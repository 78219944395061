import { useEffect, useMemo, useState } from 'react';
import { Grid, Button, MenuItem } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import TextFieldRG from 'src/components/Forms/TextFieldRG';
import { useQuery } from '@tanstack/react-query';
import CachedIcon from '@mui/icons-material/Cached';
import { ToastFun } from 'src/config/functions';
import { getEixos } from 'src/services/Planejamento/FundoNacional/eixoFinanciado';
import { getPlanoAplicacao } from 'src/services/Planejamento/FundoNacional/planoAplicacao';
import { FieldValues, UseFormSetValue, UseFormUnregister, useWatch } from 'react-hook-form';
import { EixoForms } from './Eixo';
import { EixoResponse } from './type';

export default function FormPlanoAplicacao({
  unregister,
  setValue,
  rows,
}: {
  setValue: UseFormSetValue<FieldValues>;
  unregister: UseFormUnregister<FieldValues>;
  rows?: any;
}) {
  const plano = useWatch({ name: `planoAplicacao.id` });
  const [eixos, setEixos] = useState<number[]>([0]);

  const novoEixo = () => {
    const novoEixos = [...eixos];
    const count = novoEixos[novoEixos.length - 1] + 1;
    setEixos([...novoEixos, count]);
  };
  const eixosPlano = useWatch({ name: `eixosFinanciados` });
  const excluirEixo = (index: number) => {
    if (eixos.length > 1) {
      unregister(`eixosFinanciados.${index}`);
      const metas = eixosPlano.filter((item: any, indice: number) => indice !== index);
      setValue(`eixosFinanciados`, metas);
      setEixos((prevMetas) => prevMetas.filter((eixo) => eixo !== index));
    } else {
      ToastFun('É necessário que o Plano possua ao menos um Eixo', 'error');
    }
  };

  useEffect(() => {
    if (eixosPlano) {
      setEixos([0]);
    }
    if (eixosPlano.length > 0) {
      const eixoAtualizar: number[] = [];
      eixosPlano.map((_item: any, index: number) => eixoAtualizar.push(index));
      setEixos(eixoAtualizar);
    }
  }, [eixosPlano]);

  const { data: eixosCad } = useQuery<EixoResponse>({
    queryKey: ['getEixos'],
    queryFn: getEixos,
  });

  const memoizedEixosCad = useMemo(() => eixosCad, [eixosCad]);
  const { data: rowsPlan } = useQuery({
    queryKey: ['getPlanoAplicacao'],
    queryFn: getPlanoAplicacao,
  });
  const anos = ['2019', '2020', '2021', '2022', '2023', '2024'];
  return (
    <Grid container spacing={1} justifyContent="center">
      <Grid item xs={12} md={4}>
        <TextFieldRG
          select
          disabled={rows}
          name="planoAplicacao.exercicioOrcamentario"
          label="Exercício Orçamentário"
        >
          {anos.map(
            (ano) =>
              !rowsPlan?.results.find((item: any) => item.exercicioOrcamentario === ano) && (
                <MenuItem key={ano} value={ano}>
                  {ano}
                </MenuItem>
              )
          )}
          <MenuItem
            key={rows?.planoAplicacao.exercicioOrcamentario}
            value={rows?.planoAplicacao.exercicioOrcamentario}
          >
            {rows?.planoAplicacao.exercicioOrcamentario}
          </MenuItem>
        </TextFieldRG>
      </Grid>

      <Grid item xs={12} md={4}>
        <TextFieldRG
          type="date"
          name="planoAplicacao.prazoExecucao"
          label="Prazo de Execução"
          focused
        />
      </Grid>

      <Grid item xs={12} md={4} mt={5} sx={{ textAlign: 'right' }}>
        <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={novoEixo}>
          Novo Eixo
        </Button>
      </Grid>
      <Grid item xs={12}>
        {eixos.map((item, index) => (
          <EixoForms
            key={item}
            index={item}
            eixosList={eixosPlano}
            memoizedEixosCad={memoizedEixosCad}
            setValue={setValue}
            excluirEixo={excluirEixo}
          />
        ))}
      </Grid>
      {!plano && (
        <Grid item xs={12} md={12} sx={{ textAlign: 'right' }}>
          <Button variant="contained" color="success" type="submit" startIcon={<SaveIcon />}>
            Cadastrar
          </Button>
        </Grid>
      )}
      {plano && (
        <Grid item xs={12} md={12} sx={{ textAlign: 'right' }}>
          <Button variant="contained" color="success" type="submit" startIcon={<CachedIcon />}>
            Atualizar
          </Button>
        </Grid>
      )}
    </Grid>
  );
}
