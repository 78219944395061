import { useState } from 'react';
import { Box, Grid, Stack, Button, IconButton, Chip, TextField, Grow } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { CustomContainerList } from 'src/components/CustomContainer/custom-container';
import CustomBreadcrumbs from 'src/components/CustomBreadcrumbs';
import TableContainer from 'src/components/Table';
import { GridColDef } from '@mui/x-data-grid';
import SearchIcon from '@mui/icons-material/Search';
import CadastroAeronave from '../Cadastrar/aeronave';

interface Coordinates {
  latitude: number;
  longitude: number;
}
export default function Aeronaves() {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [coordinates, setCoordinates] = useState<Coordinates | null>(null);
  const [error, setError] = useState<string | null>(null);

  const getLocation = () => {
    if (!navigator.geolocation) {
      setError('Geolocalização não é suportada pelo seu navegador.');
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        setCoordinates({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
        setError(null);
      },
      (err) => {
        setError(`Erro ao obter localização: ${err.message}`);
      }
    );
  };
  const skip = paginationModel.page * paginationModel.pageSize;
  const take = paginationModel.pageSize;

  const [id, setId] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setmodalType] = useState<string>();
  const [checked, setChecked] = useState<boolean>(false);
  const [search, setSearch] = useState<string>();

  const inputSearch = () => {
    if (checked) {
      setSearch('');
    }
    setChecked((prev: boolean) => !prev);
  };
  const rows = { results: [], total: 0 };
  //   const {
  //     data: rows,
  //     refetch: RefecthMetas,
  //     isPending,
  //   } = useQuery({
  //     queryKey: ['getMetas', skip, take, search],
  //     queryFn: getMetasGerais,
  //   });

  const handleButtonClick = (idAeronave: string, type: string) => {
    setId(idAeronave);
    setIsModalOpen(true);
    setmodalType(type);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setId(null);
  };

  const columns: GridColDef[] = [
    {
      field: 'tipoUso',
      headerName: 'TIPO USO',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'prefixo',
      headerName: 'PREFIXO',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'apelido',
      headerName: 'APELIDO',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'numeroSerie',
      headerName: 'Nº SÉRIE',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'fabricante',
      headerName: 'FABRICANTE',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'modelo',
      headerName: 'MODELO',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'horasVoo',
      headerName: 'HORAS DE VÔO',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'anoFabricacao',
      headerName: 'ANO DE FABRICAÇÃO',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
    },

    {
      field: 'status',
      headerName: 'SITUAÇÃO',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <Chip
          label={params.row.status}
          sx={{ color: 'white' }}
          color={params.row.status === 'Inativa' ? 'warning' : 'success'}
        />
      ),
    },
    {
      field: 'actions',
      headerName: '',
      headerAlign: 'center',
      minWidth: 150,
      flex: 1,
      align: 'center',
      renderCell: (params) => (
        <Box>
          <IconButton color="warning" onClick={() => handleButtonClick(params.row.id, 'Editar')}>
            <ModeEditIcon />
          </IconButton>
        </Box>
      ),
    },
  ];
  return (
    <Box sx={{ m: 'auto' }}>
      <Stack spacing={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3} sx={{ my: 2 }}>
            <Stack direction="row" spacing={2}>
              <TextField
                size="small"
                fullWidth
                onChange={(event) => setSearch(event.target.value)}
              />
              <IconButton
                color="warning"
                sx={{ background: '#FADCB2', mx: 1 }}
                onClick={inputSearch}
              >
                <SearchIcon />
              </IconButton>
            </Stack>
          </Grid>
          <Button onClick={getLocation}>Pegar Localização</Button>
          {coordinates ? (
            <div>
              <p>Latitude: {coordinates.latitude}</p>
              <p>Longitude: {coordinates.longitude}</p>
            </div>
          ) : (
            <p>Sem coordenadas ainda.</p>
          )}
          <Grid item xs={12} md={9} sx={{ textAlign: 'right', my: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleButtonClick('', 'Cadastro')}
              startIcon={<AddIcon />}
            >
              Aeronave
            </Button>
          </Grid>
          <Grid xs={12} mt={2}>
            <CustomContainerList title="Aeronaves">
              {rows && (
                <TableContainer
                  columns={columns}
                  rows={rows.results}
                  rowCount={rows.total}
                  paginationModel={paginationModel}
                  onPaginationModelChange={setPaginationModel}
                />
              )}
            </CustomContainerList>
          </Grid>
        </Grid>
        <CadastroAeronave open={isModalOpen} handleClose={handleCloseModal} />
      </Stack>
    </Box>
  );
}
