import {
  Box,
  Grid,
  IconButton,
  Modal,
  Alert,
  styled,
  TableCell,
  Divider,
  Typography,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import DrawIcon from '@mui/icons-material/Draw';
import { useQuery } from '@tanstack/react-query';
import { tratandoData, tratandoValorCentavos, valorMonetario } from 'src/config/utils';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ModalCarregamento from 'src/components/Modal/Carregamento';
import TableContainerCustom from 'src/components/Table';
import {
  getDespesasId,
  getPagamentosEmpenhoAPI,
} from 'src/services/Planejamento/FundoNacional/despesas';
import { useState } from 'react';
import { GridColDef } from '@mui/x-data-grid';

const TableCellHead = styled(TableCell)(({ theme }) => ({
  fontSize: '11px',
  fontWeight: 700,
  color: '#555555',
  textTransform: 'uppercase',
}));
const TableCellBody = styled(TableCell)(({ theme }) => ({
  fontSize: '14px',
  color: '#555555',
  textTransform: 'uppercase',
}));

export default function DespesaVisualizar({
  open,
  id,
  handleClose,
}: {
  open: boolean;
  id: string;
  handleClose: () => void;
}) {
  const include = ['eixoFinanciado', 'user'];

  const { data: rows, isPending } = useQuery({
    queryKey: ['getDespesasId', id, include],
    queryFn: getDespesasId,
  });
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const skip = paginationModel.page * paginationModel.pageSize;
  const take = paginationModel.pageSize;

  const { data: rowsPagamentos } = useQuery({
    queryKey: ['getEmpenhos', rows?.numeroEmpenho, skip, take],
    queryFn: getPagamentosEmpenhoAPI,
  });
  if (isPending) {
    return <ModalCarregamento isOpen={isPending} />;
  }
  const columns: GridColDef[] = [
    {
      field: 'numeroPagamento',
      headerName: 'Nº PAGAMENTO',
      align: 'center',
      headerAlign: 'center',
      flex: 1,
    },
    {
      field: 'valorPagamento',
      headerName: 'VALOR PAGO',
      align: 'center',
      minWidth: 150,
      flex: 1,
      headerAlign: 'center',
      renderCell: (params) => <>R$ {valorMonetario(params.row.valorPagamento)}</>,
    },
    {
      field: 'dataEmissao',
      headerName: 'DATA DE PAGAMENTO',
      align: 'center',
      minWidth: 150,
      flex: 1,
      headerAlign: 'center',
      renderCell: (params) => <> {tratandoData(params.row.dataEmissao)}</>,
    },
    {
      field: 'descricaoNaturezaPagamento',
      headerName: 'DESCRIÇÃO',
      align: 'center',
      minWidth: 150,
      flex: 2,
      headerAlign: 'center',
    },
    {
      field: 'razaoSocial',
      headerName: 'BENEFICÁRIO',
      align: 'center',
      minWidth: 150,
      flex: 2,
      headerAlign: 'center',
    },
  ];
  return (
    <div>
      {!isPending && rows && (
        <Modal open={open} onClose={handleClose}>
          <Box
            sx={{
              background: 'white',
              borderRadius: '15px',
              p: 5,
              width: {
                xl: '70%',
                md: '85%',
                sm: '70%',
              },
              maxHeight: '650px',
              overflow: 'auto',
              scrollbarWidth: 'none',
              margin: 'auto',
              mt: 3,
            }}
          >
            {rows && (
              <Grid container>
                <Grid md={12} xs={12} sx={{ textAlign: 'right' }}>
                  <IconButton aria-label="delete" onClick={handleClose}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
                <Grid md={12} xs={12}>
                  <Alert
                    sx={{ background: '#4B96DD', color: 'white', fontWeight: '700' }}
                    icon={<DrawIcon sx={{ color: 'white' }} />}
                  >
                    Despesas - Empenho
                  </Alert>
                  <TableContainer>
                    <Table sx={{ minWidth: 700, mt: 2 }} aria-label="spanning table">
                      <TableHead sx={{ background: '#f5f5f5' }}>
                        <TableRow>
                          <TableCellHead>Exercício Orçamentário</TableCellHead>
                          <TableCellHead colSpan={3}>Eixo Financiado</TableCellHead>
                          <TableCellHead colSpan={3}>Meta Geral</TableCellHead>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCellBody>{rows.planoAplicacao.exercicioOrcamentario}</TableCellBody>
                          <TableCellBody colSpan={3}>{rows.eixoFinanciado.eixoNome}</TableCellBody>
                          <TableCellBody colSpan={3}>{rows.metaGeral.nome}</TableCellBody>
                        </TableRow>
                      </TableBody>

                      <TableHead sx={{ background: '#f5f5f5' }}>
                        <TableRow>
                          <TableCellHead>Natureza</TableCellHead>
                          <TableCellHead colSpan={3}>Meta Fisica</TableCellHead>
                          <TableCellHead colSpan={3}> Instituição</TableCellHead>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCellBody>{rows.metaFisica.natureza}</TableCellBody>
                          <TableCellBody colSpan={3}>{rows.metaFisica.nome}</TableCellBody>
                          <TableCellBody colSpan={3}>{rows.instituicao}</TableCellBody>
                        </TableRow>
                      </TableBody>
                      <TableHead sx={{ background: '#f5f5f5' }}>
                        <TableRow>
                          <TableCellHead>Processo de Contratação</TableCellHead>
                          <TableCellHead colSpan={6}>Descrição </TableCellHead>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCellBody>{rows.processoSeiMatriz}</TableCellBody>
                          <TableCellBody colSpan={6}>{rows.descricao}</TableCellBody>
                        </TableRow>
                      </TableBody>

                      <TableHead sx={{ background: '#f5f5f5' }}>
                        <TableRow>
                          <TableCellHead>Empenho</TableCellHead>
                          <TableCellHead align="center">Valor Empenhado</TableCellHead>
                          <TableCellHead align="center">Valor Anulado</TableCellHead>
                          <TableCellHead align="center">Valor Pago</TableCellHead>
                          <TableCellHead align="center">Valor Complementar</TableCellHead>
                          <TableCellHead align="center">Processo Pagamento</TableCellHead>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCellBody>{rows?.numeroEmpenho}</TableCellBody>
                          <TableCellBody align="center">
                            R$ {tratandoValorCentavos(rows.valorEmpenho)}
                          </TableCellBody>
                          <TableCellBody align="center">
                            R$ {tratandoValorCentavos(rows.valorAnulado)}
                          </TableCellBody>
                          <TableCellBody align="center">
                            R$ {tratandoValorCentavos(rows.valorPago)}
                          </TableCellBody>
                          <TableCellBody align="center">
                            R$ {tratandoValorCentavos(rows.valorComplemento)}
                          </TableCellBody>
                          <TableCellBody align="center">{rows.processoSeiPagamento}</TableCellBody>
                        </TableRow>
                      </TableBody>
                      <TableHead sx={{ background: '#4B96DD', mt: 5 }}>
                        <TableRow>
                          <TableCellHead colSpan={6} sx={{ textAlign: 'center', color: 'white' }}>
                            Pagamentos
                          </TableCellHead>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rowsPagamentos?.data && (
                          <TableCellBody align="center" colSpan={12}>
                            <TableContainerCustom
                              columns={columns}
                              rows={rowsPagamentos?.data}
                              rowCount={rowsPagamentos?.total}
                              paginationModel={paginationModel}
                              onPaginationModelChange={setPaginationModel}
                            />
                          </TableCellBody>
                        )}
                        {!rowsPagamentos?.data && (
                          <TableCellBody sx={{ textAlign: 'center' }} colSpan={12}>
                            Nenhum Pagamento Encontrando
                          </TableCellBody>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Divider />
                </Grid>
              </Grid>
            )}
          </Box>
        </Modal>
      )}
    </div>
  );
}
