// TYPES / DEFAULT
import { tratandoValor } from 'src/config/utils';
import * as yup from 'yup';

export interface DadosVinculadosEmpenho {
  planoAplicacaoId: string;
  natureza: string;
  eixoFinanciadoId: string;
  processoSeiMatriz: string;
  metaFisicaId: string;
  metaInstituicaoId: string;
  usuarioCreatedId: string;
  Empenhos: Empenho[];
}

export interface Empenho {
  numeroEmpenho: number;
  valorEmpenho: any;
  processoSeiPagamento: string;
  descricao: string;
}

export const schemaEmpenhos = yup.object<DadosVinculadosEmpenho>({
  planoAplicacaoId: yup.string().required('Ano é obrigatório'),
  natureza: yup.string().required('Natureza é obrigatório'),
  eixoFinanciadoId: yup.string().required('Eixo é obrigatório'),
  processoSeiMatriz: yup.string().required('Processo Sei é obrigatório'),
  metaFisicaId: yup.string().required('Meta Física é obrigatório'),
  metaInstituicaoId: yup.string().required('Instituição é obrigatório'),
  usuarioCreatedId: yup.string(),
  Empenhos: yup
    .array()
    .of(
      yup.object().shape({
        instituicaoBeneficiadaId: yup.string(),
        unidadeBeneficiadaId: yup.string(),
        processoSeiPagamento: yup.string().required('Processo Sei é obrigatório'),
        descricao: yup.string().required('Descrição é obrigatório'),
        numeroEmpenho: yup.string().required('Número do Empenho é obrigatório'),
        dataEmpenho: yup.date().transform((value: any, originalValue: any) => {
          if (originalValue) {
            return new Date(originalValue);
          }
          return value;
        }),
        valorEmpenho: yup
          .mixed()
          .required('Empenho não Encontrado')
          .transform((value: any) => tratandoValor(value)),
        valorAnulado: yup
          .mixed()
          .required('Empenho não Encontrado')
          .transform((value: any) => tratandoValor(value)),
        valorComplemento: yup
          .mixed()
          .required('Empenho não Encontrado')
          .transform((value: any) => tratandoValor(value)),
        valorPago: yup
          .mixed()
          .required('Empenho não Encontrado')
          .transform((value: any) => tratandoValor(value)),
      })
    )
    .required(),
});

export const defaultValuesEmpenhos: DadosVinculadosEmpenho = {
  Empenhos: [],
  planoAplicacaoId: '',
  natureza: '',
  eixoFinanciadoId: '',
  processoSeiMatriz: '',
  metaFisicaId: '',
  metaInstituicaoId: '',
  usuarioCreatedId: '',
};
