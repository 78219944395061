import { useState } from 'react';
import { Box, Grid, Button, IconButton, TextField, Chip, Stack } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { CustomContainerList } from 'src/components/CustomContainer/custom-container';
import { useMutation, useQuery } from '@tanstack/react-query';
import TableContainer from 'src/components/Table';
import { GridColDef } from '@mui/x-data-grid';
import SearchIcon from '@mui/icons-material/Search';
import { ToastFun } from 'src/config/functions';
import { ErroResponse } from 'src/pages/types';
import { tratandoValorCentavos } from 'src/config/utils';
import ModalExcluir from 'src/components/Modal/Excluir';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  ExcluirRendimentoAlocacao,
  getRendimentosAlocacao,
} from 'src/services/Planejamento/FundoNacional/rendimento';
import CadastrarDestinacaoRendimento from './Cadastro';

export default function RendimentosDestinacao() {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 7,
  });

  const skip = paginationModel.page * paginationModel.pageSize;
  const take = paginationModel.pageSize;

  const [id, setId] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setmodalType] = useState<string>();
  const [checked, setChecked] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');

  const inputSearch = () => {
    if (checked) {
      setSearch('');
    }
    setChecked((prev: boolean) => !prev);
  };
  const include = ['eixoNome', 'exercicioOrcamentario', 'usuarioCreated', 'conta'];
  const { data: rows, refetch: RefecthRendimentos } = useQuery({
    queryKey: ['getRendimentos', skip, take, search, include],
    queryFn: getRendimentosAlocacao,
  });

  const handleButtonClick = (idPlano: string, type: string) => {
    setId(idPlano);
    RefecthRendimentos();
    setIsModalOpen(true);
    setmodalType(type);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const { mutate } = useMutation({
    mutationFn: ExcluirRendimentoAlocacao,
    onSuccess: (data) => {
      RefecthRendimentos();
      ToastFun('Excluido com Sucesso', 'success');
    },
    onError: (error: ErroResponse) => {
      ToastFun(error.response.data?.message, 'error');
    },
  });
  const columns: GridColDef[] = [
    {
      field: 'exercicioOrcamentario',
      headerName: 'PLANO DE APLICAÇÃO',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'eixoNome',
      headerName: 'EIXO',
      minWidth: 150,
      flex: 2,
    },

    {
      field: 'nomeMetaFisica',
      headerName: 'META FÍSICA',
      minWidth: 150,
      flex: 4,
    },

    {
      field: 'processoSei',
      headerName: 'PROCESSO SEI',
      minWidth: 150,
      align: 'center',
      headerAlign: 'center',
      flex: 2,
    },
    {
      field: 'nomeInstituicao',
      headerName: 'INSTITUIÇÃO',
      minWidth: 150,
      align: 'center',
      headerAlign: 'center',
      flex: 1,
    },

    {
      field: 'valor',
      headerName: 'VALOR',
      align: 'center',
      headerAlign: 'center',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => <>R$ {tratandoValorCentavos(params.row.valor)}</>,
    },
    {
      field: 'status',
      headerName: 'SITUAÇÃO',
      align: 'center',
      headerAlign: 'center',
      minWidth: 120,
      flex: 1,
      renderCell: (params) => (
        <Chip
          label={params.row.status}
          sx={{ color: 'white' }}
          color={params.row.status === 'Pendente' ? 'warning' : 'success'}
        />
      ),
    },
    {
      field: 'actions',
      headerName: '',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <Box>
          <IconButton
            aria-label="delete"
            onClick={() => handleButtonClick(params.row.id, 'Excluir')}
            disabled={params.row.status === 'Autorizado'}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <Box sx={{ m: 'auto' }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3} sx={{ my: 2 }}>
          <Stack direction="row" spacing={2}>
            <TextField size="small" fullWidth onChange={(event) => setSearch(event.target.value)} />
            <IconButton color="warning" sx={{ background: '#FADCB2', mx: 1 }} onClick={inputSearch}>
              <SearchIcon />
            </IconButton>
          </Stack>
        </Grid>

        <Grid item xs={12} md={9} sx={{ textAlign: 'right', my: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleButtonClick('', 'Cadastro')}
            startIcon={<AddIcon />}
          >
            Destinar Rendimento
          </Button>
        </Grid>
        <Grid xs={12} mt={2}>
          <CustomContainerList title="Destinação de Rendimentos">
            {rows && (
              <TableContainer
                columns={columns}
                rows={rows.results}
                rowCount={rows.total}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
              />
            )}
          </CustomContainerList>
        </Grid>
      </Grid>
      {isModalOpen && modalType === 'Cadastro' && (
        <CadastrarDestinacaoRendimento
          open={isModalOpen}
          handleClose={handleCloseModal}
          RefecthRendimentos={RefecthRendimentos}
        />
      )}
      {id && isModalOpen && modalType === 'Excluir' && (
        <ModalExcluir
          isOpen={isModalOpen}
          nomeAcao="Alocação de Rendimento"
          id={id}
          mutate={mutate}
          handleClose={handleCloseModal}
        />
      )}
    </Box>
  );
}
