import { Box, Stack } from '@mui/material';
import CustomBreadcrumbs from 'src/components/CustomBreadcrumbs';
import { CustomContainerCad } from 'src/components/CustomContainer/custom-container';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErroResponse } from 'src/pages/types';
import FormsProvider from 'src/components/Forms/Provider';
import { useMutation } from '@tanstack/react-query';
import { ToastFun } from 'src/config/functions';
import { AuthContext } from 'src/Context/ContextUser';
import { useContext, useState } from 'react';
import FormPlanoAplicacao from 'src/pages/Planejamento/FundoNacional/PlanoAplicacao/Formulario/Plano';
import { useNavigate } from 'react-router-dom';
import { postCadastrarPlanoAplicacao } from 'src/services/Planejamento/FundoNacional/planoAplicacao';
import { DataPlano, defaultValuesPlano, schemaPlano } from '../Formulario/type';

export default function CadastrarPlano() {
  const navigate = useNavigate();

  const { state } = useContext(AuthContext);

  const methods = useForm({
    resolver: yupResolver(schemaPlano),
    defaultValues: defaultValuesPlano,
  });

  const {
    reset,
    unregister,
    setValue,
    formState: { errors },
  } = methods;
  const { mutate, isPending } = useMutation({
    mutationFn: postCadastrarPlanoAplicacao,
    onSuccess: (data) => {
      reset(defaultValuesPlano);
      navigate('/planejamento/fundonacional/planodeaplicacao');
      ToastFun('Plano Cadastrado com sucesso', 'success');
    },
    onError: (error: ErroResponse) => {
      ToastFun(error.response.data?.message, 'error');
    },
  });

  const onSubmit = async (data: DataPlano) => {
    if (state.usuario?.id) {
      data.planoAplicacao.usuarioCreateId = state.usuario.id;
    }

    const hasNegativeValues = data.eixosFinanciados.some(
      (item: any) => item.valorInicialCusteio < 0 || item.valorInicialInvestimento < 0
    );

    if (hasNegativeValues) {
      ToastFun('Os valores não pode ser negativo', 'error');
    } else {
      mutate(data);
    }
  };

  return (
    <Box sx={{ m: 'auto' }}>
      <Stack spacing={5}>
        <CustomBreadcrumbs
          heading="Fundo Nacional de Segurança Pública"
          links={[
            { name: 'Planejamento', href: '/planejamento' },
            { name: 'Fundo Nacional', href: '/planejamento/fundonacional' },
            { name: 'Plano de Aplicação', href: '/planejamento/fundonacional/planodeaplicacao' },
            { name: 'Cadastro', href: '/planejamento/fundonacional/planodeaplicacao/cadastro' },
          ]}
        />
        <CustomContainerCad title="Plano de Aplicação do Fundo Nacional de Segurança Pública">
          <FormsProvider methods={methods} handleSubmit={onSubmit}>
            <FormPlanoAplicacao unregister={unregister} setValue={setValue} />
          </FormsProvider>
        </CustomContainerCad>
      </Stack>
    </Box>
  );
}
