import { Grid, Button, MenuItem, TextField } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import TextFieldRG from 'src/components/Forms/TextFieldRG';
import { useQuery } from '@tanstack/react-query';
import { NumericFormat } from 'react-number-format';
import { useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { getPlanoAplicacao } from 'src/services/Planejamento/FundoNacional/planoAplicacao';
import { getEixosFinanciados } from 'src/services/Planejamento/FundoNacional/eixoFinanciado';
import { getMetasFisicas } from 'src/services/Planejamento/FundoNacional/metas';
import { tratandoValorCentavos } from 'src/config/utils';
import TextFieldMoney from 'src/components/Forms/TextFieldMoney';

interface MetaFisica {
  id: string;
  metasInstituicoes: Instituicao[];
}

interface Instituicao {
  id: string;
  saldoEstimativo: number;
}

export default function FormularioRemanejamento({ setValue }: { setValue: any }) {
  const [instituicoesRetirada, setInstituicoesRetirada] = useState<Instituicao[]>([]);
  const [instituicoesRecebida, setInstituicoesRecebida] = useState<Instituicao[]>([]);
  const [valorDisponivel, setValorDisponivel] = useState<number>(0);

  const planoAplicacaoId = useWatch({ name: 'planoAplicacaoId' });
  const eixoFinanciadoId = useWatch({ name: 'eixoFinanciadoId' });
  const natureza = useWatch({ name: 'natureza' });
  const metaFisicaRetirada = useWatch({ name: 'metaFisicaRetiradaId' });
  const metaFisicaRecebida = useWatch({ name: 'metaFisicaRecebidaId' });
  const instituicaoRetiradaId = useWatch({ name: 'instituicaoRetiradaId' });
  const { data: planoAplicacao } = useQuery({
    queryKey: ['getExercicioOrcamentario'],
    queryFn: getPlanoAplicacao,
  });

  const { data: eixosFinanciados, refetch: RefecthEixos } = useQuery({
    queryKey: ['getEixosFinanciados', planoAplicacaoId],
    queryFn: getEixosFinanciados,
    enabled: !!planoAplicacaoId,
  });
  const { data: metasFisicas } = useQuery<{ total: number; results: MetaFisica[] }>({
    queryKey: ['getMetaFisica', planoAplicacaoId, eixoFinanciadoId, natureza],
    queryFn: () =>
      getMetasFisicas({
        include: ['metaInstituicaoCount', 'metaGeral', 'metasInstituicoes'],
        planoAplicacaoId,
        eixoFinanciadoId,
        natureza,
      }),
    enabled: !!planoAplicacaoId && !!eixoFinanciadoId && !!natureza,
  });

  useEffect(() => {
    if (metaFisicaRetirada) {
      const retirada = metasFisicas?.results.find((item) => item.id === metaFisicaRetirada);
      setInstituicoesRetirada(retirada?.metasInstituicoes || []);
    }
    if (metaFisicaRecebida) {
      const recebida = metasFisicas?.results.find((item) => item.id === metaFisicaRecebida);
      setInstituicoesRecebida(recebida?.metasInstituicoes || []);
    }
  }, [metaFisicaRetirada, metaFisicaRecebida, metasFisicas]);

  useEffect(() => {
    if (instituicoesRetirada.length > 0) {
      const valorDispo = instituicoesRetirada.find((item) => item.id === instituicaoRetiradaId);
      const saldo = valorDispo?.saldoEstimativo || 0;
      setValorDisponivel(saldo);
      setValue('valorDisponivel', saldo);
    }
  }, [instituicaoRetiradaId, instituicoesRetirada, setValue]);
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={12} lg={6}>
        <TextFieldRG select name="planoAplicacaoId" label="Exercício Orçamentário">
          {planoAplicacao?.results.map((item: any, indexExercicio: number) => (
            <MenuItem key={item} value={item.id} disabled={item.status === 'Pendente'}>
              {item.exercicioOrcamentario}
            </MenuItem>
          ))}
        </TextFieldRG>
      </Grid>
      <Grid item xs={12} md={12} lg={6}>
        <TextFieldRG select name="natureza" label="Natureza">
          <MenuItem value="investimento">Investimento</MenuItem>
          <MenuItem value="custeio">Custeio</MenuItem>
        </TextFieldRG>
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <TextFieldRG select name="eixoFinanciadoId" label="Eixo/Área Temática">
          {eixosFinanciados?.map((item: any, indexEixoFinanciado: number) => (
            <MenuItem key={indexEixoFinanciado} value={item.id}>
              {item.eixoNome}
            </MenuItem>
          ))}
        </TextFieldRG>
      </Grid>

      <Grid item xs={12} md={12} lg={8}>
        <TextFieldRG select name="metaFisicaRetiradaId" label="Meta Física - Retirada">
          {metasFisicas?.results.map((item: any) => (
            <MenuItem key={item.id} value={item.id} disabled={item.metaGeral.status === 'Pendente'}>
              {item.nome}
            </MenuItem>
          ))}
        </TextFieldRG>
      </Grid>
      <Grid item xs={12} md={12} lg={4}>
        <TextFieldRG select name="instituicaoRetiradaId" label="Instituição - Retirada">
          {instituicoesRetirada?.map((item: any) => (
            <MenuItem key={item.id} value={item.id}>
              {item.sigla}
            </MenuItem>
          ))}
        </TextFieldRG>
      </Grid>
      <Grid item xs={12} md={12} lg={8}>
        <TextFieldRG select name="metaFisicaRecebidaId" label="Meta Física - Recebida">
          {metasFisicas?.results.map((item: any) => (
            <MenuItem key={item.id} value={item.id}>
              {item.nome}
            </MenuItem>
          ))}
        </TextFieldRG>
      </Grid>
      <Grid item xs={12} md={12} lg={4}>
        <TextFieldRG select name="instituicaoRecebidaId" label="Instituição - Recebida">
          {instituicoesRecebida?.map((item: any) => (
            <MenuItem key={item.id} value={item.id} disabled={item.id === instituicaoRetiradaId}>
              {item.sigla}
            </MenuItem>
          ))}
        </TextFieldRG>
      </Grid>
      <Grid item xs={12} md={12} lg={6}>
        {!!valorDisponivel && (
          <TextFieldRG
            label="Valor Disponível"
            value={`R$ ${tratandoValorCentavos(valorDisponivel)}`}
            disabled
            name="valorDisponivel"
          />
        )}
        {!valorDisponivel && (
          <TextFieldRG label="Valor Disponível" value="R$ 0,00" disabled name="valorDisponivel" />
        )}
      </Grid>
      <Grid item xs={12} md={12} lg={6}>
        <TextFieldMoney label="Valor Remanejado" name="valorRemanejado" />
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <TextFieldRG label="Processo SEI" name="processoSei" />
      </Grid>
    </Grid>
  );
}
