import { Box, Button, Stack } from '@mui/material';
import CustomBreadcrumbs from 'src/components/CustomBreadcrumbs';
import ConnectingAirportsIcon from '@mui/icons-material/ConnectingAirports';
import Grid from '@mui/material/Unstable_Grid2';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import DescriptionIcon from '@mui/icons-material/Description';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import PeopleIcon from '@mui/icons-material/People';

interface CustomButtonProps {
  children: React.ReactNode;
  disabled?: boolean;
  icon: React.ReactNode;
  LinkApp: string;
}

function CustomButton({ children, icon, LinkApp, disabled }: CustomButtonProps) {
  return (
    <Button
      variant="soft"
      color="success"
      size="large"
      disabled={disabled}
      startIcon={icon}
      fullWidth
      href={`ciopaer/${LinkApp}`}
      sx={{
        py: 6,
        borderRadius: 2,
        // width: 260,
      }}
    >
      {children}
    </Button>
  );
}

export default function AeronaveMenu() {
  return (
    <Box sx={{ m: 'auto' }}>
      <Stack spacing={3}>
        <CustomBreadcrumbs
          heading="Centro Integrado de Operações Aéreas
"
          links={[{ name: 'Menu', href: '/operacional/ciopaer' }]}
        />

        <Grid container spacing={3}>
          <Grid xs={12} md={4} lg={3}>
            <CustomButton LinkApp="aeronaves" icon={<ConnectingAirportsIcon />}>
              Aeronaves
            </CustomButton>
          </Grid>
          <Grid xs={12} md={4} lg={3}>
            <CustomButton LinkApp="ocorrencias" icon={<NotificationImportantIcon />}>
              Ocorrências
            </CustomButton>
          </Grid>

          <Grid xs={12} md={4} lg={3}>
            <CustomButton LinkApp="relatorios" icon={<DescriptionIcon />}>
              Relatórios
            </CustomButton>
          </Grid>
          <Grid xs={12} md={4} lg={3}>
            <CustomButton LinkApp="servidores" icon={<PeopleIcon />}>
              Servidores
            </CustomButton>
          </Grid>
        </Grid>
      </Stack>
    </Box>
  );
}
