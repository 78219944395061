import { useState } from 'react';
import {
  Box,
  Grid,
  Stack,
  Button,
  IconButton,
  Chip,
  TextField,
  Grow,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { CustomContainerList } from 'src/components/CustomContainer/custom-container';
import CustomBreadcrumbs from 'src/components/CustomBreadcrumbs';
import { useQuery } from '@tanstack/react-query';
import TableContainer from 'src/components/Table';
import { GridColDef } from '@mui/x-data-grid';
import { SomaTotal, tratandoData, tratandoValorCentavos } from 'src/config/utils';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import SearchIcon from '@mui/icons-material/Search';
import { Navigate, useNavigate } from 'react-router-dom';
import ModalCarregamento from 'src/components/Modal/Carregamento';
import { getMetasGerais } from 'src/services/Planejamento/FundoNacional/metas';

export default function MetasGeraisTabelaAutorizacao() {
  const navigate = useNavigate();
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const skip = paginationModel.page * paginationModel.pageSize;
  const take = paginationModel.pageSize;

  const [id, setId] = useState<string | null>(null);
  const [search, setSearch] = useState<string>('Pendente');
  const [checked, setChecked] = useState<boolean>(false);
  const include = ['metaFisicaCount', 'user', 'exercicioOrcamentario'];
  const dados = {
    skip,
    take,
    filters: { search },
    include,
  };
  const {
    data: rows,
    refetch: RefecthMetas,
    isPending,
  } = useQuery({
    queryKey: ['getMetas', skip, take, search, include],
    queryFn: () => getMetasGerais(dados),
  });
  if (isPending) {
    return <ModalCarregamento isOpen={isPending} />;
  }
  const columns: GridColDef[] = [
    {
      field: 'exercicioOrcamentario',
      headerName: 'PLANO DE APLICAÇÃO',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
    },
    {
      field: 'nome',
      headerName: 'META GERAL',
      flex: 4,
      minWidth: 250,
    },
    {
      field: '_count',
      headerName: 'METAS FÍSICAS',
      flex: 1,
      minWidth: 150,

      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => <>{params.row.dadosMetasFisicasCount}</>,
    },

    {
      field: 'criadoEm',
      headerName: 'CADASTRADO EM',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => tratandoData(params.row.criadoEm),
    },
    {
      field: 'usuarioCreate',
      headerName: 'CADASTRADO POR',
      flex: 2,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => params.row.usuarioCreate.nome,
    },
    {
      field: 'status',
      headerName: 'SITUAÇÃO',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <Chip
          label={params.row.status}
          sx={{ color: 'white' }}
          color={params.row.status === 'Pendente' ? 'warning' : 'success'}
        />
      ),
    },
    {
      field: 'actions',
      headerName: '',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <Box>
          <IconButton color="primary" onClick={() => navigate(`meta/${params.row.id}`)}>
            <VisibilityIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <Box sx={{ m: 'auto' }}>
      <Grid container spacing={2}>
        <Grid xs={12} mt={2}>
          <CustomContainerList title="Metas Gerais">
            {rows && (
              <TableContainer
                columns={columns}
                rows={rows.results}
                rowCount={rows.total}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
              />
            )}
          </CustomContainerList>
        </Grid>
      </Grid>
    </Box>
  );
}
