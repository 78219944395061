// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Badge, Box, Button, Container, Stack } from '@mui/material';
import LineAxisIcon from '@mui/icons-material/LineAxis';
import CustomBreadcrumbs from 'src/components/CustomBreadcrumbs';
import DrawIcon from '@mui/icons-material/Draw';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import Grid from '@mui/material/Unstable_Grid2';
import AirlineStopsIcon from '@mui/icons-material/AirlineStops';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import DescriptionIcon from '@mui/icons-material/Description';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'src/Context/ContextUser';

interface CustomButtonProps {
  children: React.ReactNode;
  disabled?: boolean;
  icon: React.ReactNode;
  LinkApp: string;
}

function CustomButton({ children, icon, LinkApp, disabled }: CustomButtonProps) {
  return (
    <Button
      variant="soft"
      color="success"
      size="large"
      disabled={disabled}
      startIcon={icon}
      fullWidth
      href={`/planejamento/fundonacional/${LinkApp}`}
      sx={{
        py: 6,
        borderRadius: 2,
        // width: 260,
      }}
    >
      {children}
    </Button>
  );
}

export default function FundoNacionalMenu() {
  const { state } = useContext(AuthContext);
  const [modAutorizacao, setModAutorizacao] = useState(false);
  useEffect(() => {
    if (
      (state.usuario && state.usuario?.permissoes.includes('*')) ||
      state.usuario?.permissoes.includes('PLANEJAMENTO_*')
    ) {
      setModAutorizacao(true);
    }
  }, [state.usuario]);

  return (
    <Box sx={{ m: 'auto' }}>
      <Stack spacing={3}>
        <CustomBreadcrumbs
          heading="Fundo Nacional de Segurança Pública"
          links={[
            { name: 'Planejamento', href: '/planejamento' },
            { name: 'Fundo Nacional', href: '/planejamento/fundonacional' },
          ]}
        />

        <Grid container spacing={3}>
          <Grid xs={12} md={4} lg={3}>
            <CustomButton LinkApp="planodeaplicacao" icon={<DrawIcon />}>
              Plano de Aplicação
            </CustomButton>
          </Grid>

          <Grid xs={12} md={4} lg={3}>
            <CustomButton LinkApp="metas" icon={<LineAxisIcon />}>
              Metas
            </CustomButton>
          </Grid>
          <Grid xs={12} md={4} lg={3}>
            <CustomButton LinkApp="despesas" icon={<TrendingDownIcon />}>
              Despesas
            </CustomButton>
          </Grid>
          <Grid xs={12} md={4} lg={3}>
            <CustomButton LinkApp="movimentacao-financeira" icon={<CurrencyExchangeIcon />}>
              Movimentação Financeira
            </CustomButton>
          </Grid>
          <Grid xs={12} md={4} lg={3}>
            <CustomButton LinkApp="relatorios" icon={<DescriptionIcon />}>
              Relatórios
            </CustomButton>
          </Grid>
          {modAutorizacao && (
            <Grid xs={12} md={4} lg={3}>
              <CustomButton LinkApp="autorizacao" icon={<TaskAltIcon />}>
                Autorização
              </CustomButton>
            </Grid>
          )}
        </Grid>
      </Stack>
    </Box>
  );
}
