import { Box } from '@mui/material';
import { Navigate } from 'react-router-dom';
import { RoutePrivate } from 'src/Auth/RouteProtect';
import LayoutSidebar from 'src/components/Layout';
import AeronaveMenu from 'src/pages/Operacional/Ciopaer';
import Abastecimento from 'src/pages/Operacional/Ciopaer/Aeronave/Abastecimento';
import Aeronaves from 'src/pages/Operacional/Ciopaer/Aeronave';
import Ocorrencias from 'src/pages/Operacional/Ciopaer/Ocorrencia';

export const Operacional = {
  element: <RoutePrivate element={<LayoutSidebar />} permission="OPERACIONAL" />,
  path: '/operacional',
  children: [
    {
      element: <Navigate to="dashboard" />,
      path: '',
    },
    {
      element: <RoutePrivate element={<Box>oi</Box>} permission="PLANEJAMENTO" />,
      path: 'dashboard',
    },
    {
      element: <RoutePrivate element={<AeronaveMenu />} permission="OPERACIONAL_CIOPAER" />,
      path: 'ciopaer',
    },
    {
      element: <RoutePrivate element={<Aeronaves />} permission="OPERACIONAL_CIOPAER" />,
      path: 'ciopaer/aeronaves',
    },
    {
      element: <RoutePrivate element={<Abastecimento />} permission="OPERACIONAL_CIOPAER" />,
      path: 'ciopaer/abastecimentos',
    },
    {
      element: <RoutePrivate element={<Ocorrencias />} permission="OPERACIONAL_CIOPAER" />,
      path: 'ciopaer/ocorrencias',
    },
    // {
    //   element: <RoutePrivate element={<Dashboard />} permission="PLANEJAMENTO" />,
    //   path: 'dashboard',
    // },
    // {
    //   element: <RoutePrivate element={<FundoNacionalMenu />} permission="FUNDO_NACIONAL" />,
    //   path: 'fundonacional',
    // },
  ],
};
