import { useState } from 'react';
import {
  Box,
  Grid,
  Button,
  IconButton,
  TextField,
  Typography,
  MenuItem,
  Stack,
  Tooltip,
} from '@mui/material';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { CustomContainerList } from 'src/components/CustomContainer/custom-container';
import { useMutation, useQuery } from '@tanstack/react-query';
import TableContainer from 'src/components/Table';
import { GridColDef } from '@mui/x-data-grid';
import SearchIcon from '@mui/icons-material/Search';
import { ToastFun } from 'src/config/functions';
import { ErroResponse } from 'src/pages/types';
import ModalExcluir from 'src/components/Modal/Excluir';
import { ExcluirMetaFisica, getMetasFisicas } from 'src/services/Planejamento/FundoNacional/metas';
import { getPlanoAplicacao } from 'src/services/Planejamento/FundoNacional/planoAplicacao';
import { getEixosFinanciados } from 'src/services/Planejamento/FundoNacional/eixoFinanciado';
import { tratandoValorCentavos } from 'src/config/utils';
import { getInstituicoes } from 'src/services/Admin/get';
import CadastroMetaGeral from './Cadastro/MetaGeral';
import MetaFisicaFinanceiro from './Financeiro/MetaFisica';

export default function MetaFisica() {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const skip = paginationModel.page * paginationModel.pageSize;
  const take = paginationModel.pageSize;

  const include = ['metaInstituicaoCount', 'metaGeral'];

  const [id, setId] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setmodalType] = useState<string>();
  const [checked, setChecked] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [planoAplicacaoId, setPlanoAplicacaoId] = useState<string>('');
  const [eixoFinanciadoId, setEixoFinanciadoId] = useState<string>('');
  const [instituicaoId, setInstituicaoId] = useState<string>('');

  const inputSearch = () => {
    if (checked) {
      setSearch('');
    }
    setChecked((prev: boolean) => !prev);
  };
  const buscarMetas = {
    skip,
    take,
    filters: { search },
    include,
    planoAplicacaoId,
    eixoFinanciadoId,
    instituicaoId,
  };

  const {
    data: rows,
    refetch: RefecthMetas,
    isPending,
  } = useQuery({
    queryKey: [
      'getMetaFisica',
      skip,
      take,
      planoAplicacaoId,
      eixoFinanciadoId,
      search,
      instituicaoId,
    ],
    queryFn: () => getMetasFisicas(buscarMetas),
  });
  const handleButtonClick = (idPlano: string, type: string) => {
    setId(idPlano);
    setIsModalOpen(true);
    setmodalType(type);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const { mutate } = useMutation({
    mutationFn: ExcluirMetaFisica,
    onSuccess: (data) => {
      ToastFun('Excluido com Sucesso', 'success');
      RefecthMetas();
      handleCloseModal();
    },
    onError: (error: ErroResponse) => {
      ToastFun(error.response.data?.message, 'error');
    },
  });

  const columns: GridColDef[] = [
    {
      field: 'nome',
      headerName: 'META FÍSICA',
      flex: 4,
      minWidth: 250,
    },
    {
      field: 'natureza',
      headerName: 'NATUREZA',
      align: 'center',
      headerAlign: 'center',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => params.row.natureza.toUpperCase(),
    },
    {
      field: 'valorInicial',
      headerName: 'VALOR INICIAL',
      align: 'center',
      minWidth: 150,
      flex: 1,
      headerAlign: 'center',
      renderCell: (params) => (
        <>R$ {tratandoValorCentavos(params.row.dadosMetasInsitituicao._sum.valorInicial)}</>
      ),
    },
    {
      field: 'Instituições',
      minWidth: 150,
      headerName: 'INSTITUIÇÕES',
      align: 'center',
      flex: 1,
      headerAlign: 'center',
      renderCell: (params) => (
        <Tooltip
          title={params.row.instituicoes.map((item: any) => (
            <>{item} </>
          ))}
        >
          <Typography>{params.row.dadosMetasInsitituicao._count.id}</Typography>
        </Tooltip>
      ),
    },

    {
      field: 'actions',
      headerName: '',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,

      renderCell: (params) => (
        <Box>
          {params.row.metaGeral.status === 'Pendente' && (
            <IconButton
              sx={{ color: 'gray' }}
              onClick={() => handleButtonClick(params.row.id, 'Excluir')}
            >
              <DeleteIcon />
            </IconButton>
          )}
          <IconButton color="primary" href={`metas/${params.row.id}`}>
            <VisibilityIcon />
          </IconButton>
          <IconButton
            color="success"
            onClick={() => handleButtonClick(params.row.id, 'Financeiro')}
          >
            <MonetizationOnIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  const { data: rowPlanosAplicacao } = useQuery({
    queryKey: ['getExercicioOrcamentario'],
    queryFn: getPlanoAplicacao,
  });
  const { data: eixosFinanciados } = useQuery({
    queryKey: ['getEixosFinanciados', planoAplicacaoId],
    queryFn: getEixosFinanciados,
    enabled: !!planoAplicacaoId,
  });
  const { data: rowInstituicoes } = useQuery({
    queryKey: ['getInstituicoes'],
    queryFn: getInstituicoes,
  });
  return (
    <Box sx={{ m: 'auto' }}>
      <Grid item xs={12} md={12} sx={{ textAlign: 'right', mb: 3 }}>
        <Button
          href="/planejamento/fundonacional/metas/cadastro"
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
        >
          Meta Física
        </Button>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={2} xl={2} sx={{ my: 2 }}>
          <TextField
            size="small"
            select
            name="exercicioOrcamentario"
            label="Ano"
            fullWidth
            onChange={(event) => setPlanoAplicacaoId(event.target.value)}
          >
            {rowPlanosAplicacao &&
              rowPlanosAplicacao?.results?.map((item: any, indexExercicio: number) => (
                <MenuItem key={indexExercicio} value={item.id}>
                  {item.exercicioOrcamentario}
                </MenuItem>
              ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={2} xl={4} sx={{ my: 2 }}>
          <TextField
            size="small"
            select
            name="eixoFinanciado"
            label="Eixo Financiado"
            fullWidth
            onChange={(event) => setEixoFinanciadoId(event.target.value)}
          >
            {eixosFinanciados?.map((item: any, indexEixoFinanciado: number) => (
              <MenuItem key={indexEixoFinanciado} value={item.id}>
                {item.eixoNome}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={2} xl={2} sx={{ my: 2 }}>
          <TextField
            size="small"
            select
            name="instituicaoId"
            label="Instituição"
            fullWidth
            onChange={(event) => setInstituicaoId(event.target.value)}
          >
            {rowInstituicoes?.results.map((item: any) => (
              <MenuItem key={item.id} value={item.id}>
                {item.sigla}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={5} xl={4} sx={{ my: 2 }}>
          <Stack direction="row" spacing={2}>
            <TextField size="small" fullWidth onChange={(event) => setSearch(event.target.value)} />
            <IconButton color="warning" sx={{ background: '#FADCB2', mx: 1 }} onClick={inputSearch}>
              <SearchIcon />
            </IconButton>
          </Stack>
        </Grid>

        <Grid xs={12} mt={2}>
          <CustomContainerList title="Metas Físicas">
            {rows && (
              <TableContainer
                columns={columns}
                rows={rows.results}
                rowCount={rows.total}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
              />
            )}
          </CustomContainerList>
        </Grid>
      </Grid>
      {isModalOpen && modalType === 'Cadastro' && (
        <CadastroMetaGeral
          open={isModalOpen}
          handleClose={handleCloseModal}
          RefecthMetas={RefecthMetas}
        />
      )}

      {id && isModalOpen && modalType === 'Financeiro' && (
        <MetaFisicaFinanceiro open={isModalOpen} handleClose={handleCloseModal} id={id} />
      )}

      {id && isModalOpen && modalType === 'Excluir' && (
        <ModalExcluir
          isOpen={isModalOpen}
          nomeAcao="Meta Física"
          id={id}
          mutate={mutate}
          handleClose={handleCloseModal}
        />
      )}
    </Box>
  );
}
