import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Button, Grid } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import { useContext } from 'react';
import { AuthContext } from 'src/Context/ContextUser';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  boxShadow: 24,
  background: 'white',
  borderRadius: '10px',
  p: 4,
};

export default function ModalVerificar({
  title,
  dados,
  isModalOpen,
  setIsModalOpen,
  mutate,
}: {
  title: string;
  dados: any;
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  mutate: any;
}) {
  const { state } = useContext(AuthContext);
  return (
    <div>
      <Modal open={isModalOpen}>
        <Box sx={style}>
          <Box sx={{ textAlign: 'center' }}>
            <WarningAmberIcon sx={{ fontSize: 40 }} color="warning" />
            <Typography variant="h6" sx={{ color: '#6D6C6C' }}>
              {title} já foi cadastrado, deseja cadastrar novamente?
            </Typography>
          </Box>
          <Box sx={{ textAlign: 'center', my: 3 }}>
            <Grid container spacing={2} justifyContent="center">
              <Grid item md={5}>
                <Button
                  variant="soft"
                  fullWidth
                  startIcon={<ClearIcon />}
                  onClick={() => {
                    setIsModalOpen(false);
                  }}
                  color="error"
                >
                  NÃO
                </Button>
              </Grid>
              <Grid item md={5}>
                <Button
                  variant="soft"
                  color="success"
                  fullWidth
                  startIcon={<CheckIcon />}
                  onClick={() => {
                    dados.verification = true;
                    dados.usuarioCreateId = state.usuario?.id;
                    dados.usuarioCreatedId = state.usuario?.id;
                    mutate(dados);
                  }}
                >
                  SIM
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
