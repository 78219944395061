import { Alert, Box, Chip, Grid, IconButton, Modal, styled, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DrawIcon from '@mui/icons-material/Draw';
import { useQuery } from '@tanstack/react-query';
import { tratandoData, tratandoValorCentavos } from 'src/config/utils';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ModalCarregamento from 'src/components/Modal/Carregamento';
import { getMetaFisica } from 'src/services/Planejamento/FundoNacional/metas';
import { getRemanejamentoIdMeta } from 'src/services/Planejamento/FundoNacional/remanejamento';
import { GridColDef } from '@mui/x-data-grid';
import { useState } from 'react';
import { CustomContainerList } from 'src/components/CustomContainer/custom-container';

const TableCellHead = styled(TableCell)(({ theme }) => ({
  fontSize: '11px',
  fontWeight: 700,
  color: '#555555',
  textTransform: 'uppercase',
}));
const TableCellBody = styled(TableCell)(({ theme }) => ({
  fontSize: '14px',
  color: '#555555',
  textTransform: 'uppercase',
}));

export default function MetaFisicaVisualizaRemanejamentos({ id }: { id: string }) {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const skip = paginationModel.page * paginationModel.pageSize;
  const take = paginationModel.pageSize;

  const include = ['metaFisicaCompleto', 'metasInstituicoes'];
  const { data: meta } = useQuery({
    queryKey: ['getMetaFisica', id, include],
    queryFn: () => getMetaFisica(id, include),
  });

  const { data: rowsMeta, isPending } = useQuery({
    queryKey: ['getRemanejamentoIdMeta', id],
    queryFn: getRemanejamentoIdMeta,
  });

  if (isPending) {
    return <ModalCarregamento isOpen={isPending} />;
  }
  return (
    <Box
      sx={{
        p: 2,
        margin: 'auto',
        mt: 1,
      }}
    >
      <Grid container>
        <Grid md={12} xs={12}>
          <CustomContainerList title="Remanejamentos" type="detalhes">
            <TableContainer>
              {/* DADOS DA META - ANO - EIXO -META GERAL */}
              <Table sx={{ minWidth: '100%', mt: 2 }} aria-label="spanning table">
                <TableHead sx={{ background: '#f5f5f5' }}>
                  <TableRow>
                    <TableCellHead>Plano de Aplicacao</TableCellHead>
                    <TableCellHead colSpan={2}>Tipo de Despesa</TableCellHead>
                    <TableCellHead colSpan={8}>Meta Física em Destaque </TableCellHead>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCellBody>{meta?.planoAplicacao.exercicioOrcamentario}</TableCellBody>
                    <TableCellBody colSpan={2}> {meta?.tipoDespesaData.nome}</TableCellBody>
                    <TableCellBody colSpan={8}>{meta?.nome}</TableCellBody>
                  </TableRow>
                </TableBody>
                <TableHead sx={{ background: '#f5f5f5' }}>
                  <TableRow>
                    <TableCellHead>Ação Na Meta Física em Destaque</TableCellHead>
                    <TableCellHead colSpan={3}>Valor </TableCellHead>
                    <TableCellHead>Meta Física utilizada na Ação </TableCellHead>
                    <TableCellHead colSpan={2}>Meta Física em Destaque </TableCellHead>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowsMeta?.results.map((item: any, index: number) => (
                    <TableRow key={item.id}>
                      <TableCellBody>{item?.acao}</TableCellBody>
                      <TableCellBody key={index} colSpan={3}>
                        R$ {tratandoValorCentavos(item.valorRemanejado)}
                      </TableCellBody>
                      <TableCellBody key={index}>{item.metaFisicaAcao}</TableCellBody>
                      <TableCellBody key={index}>
                        <Chip
                          label={item.status}
                          sx={{ color: 'white' }}
                          color={item.status === 'Pendente' ? 'warning' : 'success'}
                        />
                      </TableCellBody>
                    </TableRow>
                  ))}
                  {rowsMeta?.results.length === 0 && (
                    <TableCellBody colSpan={5} sx={{ textAlign: 'center' }}>
                      Não houve remanejamentos relacionados a esta meta física.
                    </TableCellBody>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </CustomContainerList>
        </Grid>
      </Grid>
    </Box>
  );
}
