import { Alert, Grid, IconButton, Modal } from '@mui/material';
import Box from '@mui/material/Box';
import { useContext, useState } from 'react';
import FormsProvider from 'src/components/Forms/Provider';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import DrawIcon from '@mui/icons-material/Draw';
import { AuthContext } from 'src/Context/ContextUser';
import { useMutation, useQuery } from '@tanstack/react-query';
import { ToastFun } from 'src/config/functions';
import { ErroResponse } from 'src/pages/types';
import ModalCarregamento from 'src/components/Modal/Carregamento';
import {
  postAlocarRendimento,
  postCadastrarRendimento,
  postVerificaRendimento,
} from 'src/services/Planejamento/FundoNacional/rendimento';
import ModalVerificar from 'src/components/Modal/Verificar';
import DestinacaoRendimentoForms from '../Formulario';
import { schemaRendimentoAlocacao } from '../Formulario/type';

export default function CadastrarDestinacaoRendimento({
  RefecthRendimentos,
  open,
  handleClose,
}: {
  RefecthRendimentos: () => void;
  open: boolean;
  handleClose: () => void;
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const methods = useForm({ resolver: yupResolver(schemaRendimentoAlocacao) });
  const { setValue, watch } = methods;
  const { state } = useContext(AuthContext);

  const { data: rendimentos } = useQuery({
    queryKey: ['getVerificarRendimento', watch()],
    queryFn: postVerificaRendimento,
    enabled: !!watch(),
  });
  const { mutate, isPending } = useMutation({
    mutationFn: postAlocarRendimento,
    onSuccess: (data) => {
      ToastFun('Alocação de Rendimento cadastrada com sucesso!', 'success');
      RefecthRendimentos();
      handleClose();
    },
    onError: (error: ErroResponse) => {
      ToastFun(error.response.data?.message, 'error');
    },
  });

  const onSubmit = async (data: any) => {
    data.usuarioCreatedId = state.usuario?.id;
    if (rendimentos?.length > 0) {
      setIsModalOpen(true);
    } else if (data.valor <= 0) {
      ToastFun('O valor não pode ser negativo!', 'error');
    } else {
      mutate(data);
    }
  };
  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          background: 'white',
          borderRadius: '15px',
          p: 5,
          width: {
            xl: '58%',
            md: '75%',
            sm: '70%',
          },
          maxHeight: '95%',
          overflow: 'auto',
          scrollbarWidth: 'none',
          margin: 'auto',
          mt: 3,
        }}
      >
        <Grid container>
          <Grid item md={12} xs={12} sx={{ textAlign: 'right' }}>
            <IconButton aria-label="delete" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item md={12} xs={12}>
            <Alert
              sx={{ background: '#019362', color: 'white', fontWeight: '700' }}
              icon={<DrawIcon sx={{ color: 'white' }} />}
            >
              Destinação de Rendimento - Cadastro
            </Alert>
            <Box sx={{ my: 1 }}>
              <FormsProvider methods={methods} handleSubmit={onSubmit}>
                <DestinacaoRendimentoForms setValue={setValue} />
              </FormsProvider>
            </Box>
          </Grid>
        </Grid>
        <ModalVerificar
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          dados={methods.getValues()}
          mutate={mutate}
          title="Rendimento"
        />
      </Box>
    </Modal>
  );
}
