import { useContext, useState } from 'react';
import CustomBreadcrumbs from 'src/components/CustomBreadcrumbs';
import { Box, Grid, Button, IconButton, TextField, Chip, Stack } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { CustomContainerList } from 'src/components/CustomContainer/custom-container';
import TableContainer from 'src/components/Table';
import { GridColDef } from '@mui/x-data-grid';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SearchIcon from '@mui/icons-material/Search';
import { AuthContext } from 'src/Context/ContextUser';
import DeleteIcon from '@mui/icons-material/Delete';
import CachedIcon from '@mui/icons-material/Cached';
import ModalExcluir from 'src/components/Modal/Excluir';
import { ExcluirDespesa, getDespesas } from 'src/services/Planejamento/FundoNacional/despesas';
import { useMutation, useQuery } from '@tanstack/react-query';
import { ToastFun } from 'src/config/functions';
import { tratandoData, tratandoValorCentavos, valorMonetario } from 'src/config/utils';
import { ErroResponse } from 'src/pages/types';
import DespesaVisualizar from './Empenhos/Visualizar';

export default function Empenhos() {
  const [value, setValue] = useState(0);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const { state, logout } = useContext(AuthContext);
  const [search, setSearch] = useState<string>('');
  const include = ['metaFisica', 'user'];
  const [checked, setChecked] = useState<boolean>(false);
  const skip = paginationModel.page * paginationModel.pageSize;
  const take = paginationModel.pageSize;

  const { data: rows, refetch } = useQuery({
    queryKey: ['getDespesas', skip, take, search, include],
    queryFn: getDespesas,
  });
  console.log(rows);
  const [id, setId] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [type, setType] = useState('');

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleButtonClick = (despesaId: string, typeAction: string) => {
    setId(despesaId);
    setIsModalOpen(true);
    setType(typeAction);
  };

  const { mutate } = useMutation({
    mutationFn: ExcluirDespesa,
    onSuccess: (data) => {
      ToastFun('Excluido com Sucesso', 'success');
      refetch();
      handleCloseModal();
    },
    onError: (error: ErroResponse) => {
      ToastFun(error.response.data?.message, 'error');
    },
  });
  const columns: GridColDef[] = [
    {
      field: 'numeroEmpenho',
      headerName: 'EMPENHO',
      flex: 1,
      minWidth: 100,
    },

    {
      field: 'descricao',
      headerName: 'DESCRIÇÂO',
      flex: 3,
    },
    {
      field: 'valorEmpenho',
      headerName: 'VALOR EMPENHADO',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell: (params) => <> R$ {tratandoValorCentavos(params.row.valorEmpenho)}</>,
    },
    {
      field: 'dataEmpenho',
      headerName: 'DATA DO EMPENHO',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell: (params) => <>{tratandoData(params.row.dataEmpenho)}</>,
    },

    {
      field: 'processoSeiPagamento',
      headerName: 'PROCESSO DE PAGAMENTO',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
    },
    {
      field: 'usuarioCreate.nome',
      headerName: 'CRIADO POR',
      headerAlign: 'center',
      align: 'center',
      flex: 1.5,
      renderCell: (params) => <>{params.row.usuarioCreate.nome}</>,
    },
    {
      field: 'actions',
      headerName: '',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <Box>
          <IconButton
            color="primary"
            onClick={() => handleButtonClick(params.row.id, 'Visualizar')}
          >
            <VisibilityIcon />
          </IconButton>
          <IconButton
            sx={{ color: 'gray' }}
            onClick={() => handleButtonClick(params.row.id, 'Excluir')}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      ),
    },
  ];
  const inputSearch = () => {
    if (checked) {
      setSearch('');
    }
    setChecked((prev: boolean) => !prev);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setId(null);
  };

  return (
    <Box sx={{ m: 'auto' }}>
      <Stack spacing={2}>
        <CustomBreadcrumbs
          heading="Fundo Nacional de Segurança Pública"
          links={[
            { name: 'Planejamento', href: '/planejamento' },
            { name: 'Fundo Nacional', href: '/planejamento/fundonacional' },
            {
              name: 'Despesas',
              href: '/planejamento/fundonacional/Despesas',
            },
          ]}
        />
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} sx={{ my: 2 }}>
            <Stack direction="row" spacing={2}>
              <TextField
                size="small"
                fullWidth
                onChange={(event) => setSearch(event.target.value)}
              />
              <IconButton
                color="warning"
                sx={{ background: '#FADCB2', mx: 1 }}
                onClick={inputSearch}
              >
                <SearchIcon />
              </IconButton>
            </Stack>
          </Grid>

          <Grid item xs={12} md={8} sx={{ textAlign: 'right', my: 2 }}>
            {state.usuario?.permissoes.includes('*') && (
              <Button variant="contained" color="success" startIcon={<CachedIcon />} sx={{ mx: 2 }}>
                Sincronizar Pagamento
              </Button>
            )}

            <Button
              variant="contained"
              color="primary"
              href="/planejamento/fundonacional/despesas/cadastro"
              startIcon={<AddIcon />}
            >
              Vincular Empenho
            </Button>
          </Grid>
          <Grid xs={12} mt={2}>
            <CustomContainerList title="Empenhos Vinculados">
              {rows && (
                <TableContainer
                  columns={columns}
                  rows={rows.results}
                  rowCount={rows.total}
                  paginationModel={paginationModel}
                  onPaginationModelChange={setPaginationModel}
                />
              )}
            </CustomContainerList>
          </Grid>
        </Grid>

        {id && isModalOpen && type === 'Visualizar' && (
          <DespesaVisualizar open={isModalOpen} handleClose={handleCloseModal} id={id} />
        )}
        {id && isModalOpen && type === 'Excluir' && (
          <ModalExcluir
            isOpen={isModalOpen}
            nomeAcao="Despesa"
            id={id}
            mutate={mutate}
            handleClose={handleCloseModal}
          />
        )}
      </Stack>
    </Box>
  );
}
