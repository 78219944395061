import DashboardIcon from '@mui/icons-material/Dashboard';
import SnippetFolderIcon from '@mui/icons-material/SnippetFolder';
import PeopleIcon from '@mui/icons-material/People';
import AddHomeIcon from '@mui/icons-material/AddHome';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import WarningIcon from '@mui/icons-material/Warning';
import LockIcon from '@mui/icons-material/Lock';
import { PriceChange } from '@mui/icons-material';

export interface Module {
  title: string;
  link: string;
  icon: JSX.Element;
  permissao?: string;
  children?: any;
}

export const administrador: Module[] = [
  {
    title: 'Usuários',
    link: '/administrador/usuarios',
    icon: <PeopleIcon />,
  },
  {
    title: 'Unidades',
    link: '/administrador/unidades',
    icon: <AddHomeIcon />,
  },
  {
    title: 'Despesas',
    link: '/administrador/despesas',
    icon: <PriceChange />,
  },
  {
    title: 'Permissões',
    link: '/administrador/permissoes',
    icon: <LockIcon />,
  },

  //   {
  //     title: 'Módulos',
  //     link: 'modulos',
  //     icon: <PeopleIcon />,
  //   },
];
export const planejamento: Module[] = [
  {
    title: 'Dashboard',
    link: '/planejamento/dashboard',
    icon: <DashboardIcon />,
    permissao: 'PLANEJAMENTO',
  },
  //   {
  //     title: 'Notificações',
  //     link: '/planejamento/notificacao',
  //     icon: <NotificationsActiveIcon />,
  //     permissao: 'MODULE_NOTIFICACAO_PLANEJAMENTO',
  //   },
  {
    title: 'Fundo Nacional',
    link: '/planejamento/fundonacional',
    icon: <SnippetFolderIcon />,
    permissao: 'FUNDO_NACIONAL',
    children: [
      {
        segment: 'sales',
        title: 'Sales',
      },
      {
        segment: 'traffic',
        title: 'Traffic',
      },
    ],
  },
  //   {
  //     title: 'Convênio',
  //     link: '/planejamento/convenio',
  //     icon: <SubjectIcon />,
  //     permissao: 'CONVENIO',
  //   },
];
export const operacional: Module[] = [
  {
    title: 'Dashboard',
    link: '/operacional/dashboard',
    icon: <DashboardIcon />,
    permissao: 'OPERACIONAL',
  },
  //   {
  //     title: 'Notificações',
  //     link: '/planejamento/notificacao',
  //     icon: <NotificationsActiveIcon />,
  //     permissao: 'MODULE_NOTIFICACAO_PLANEJAMENTO',
  //   },
  {
    title: 'CIOPAER',
    link: '/operacional/ciopaer',
    icon: <AirplanemodeActiveIcon />,
    permissao: 'CIOPAER',
  },
];
