import { Box, Grid, IconButton, MenuItem, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useQuery } from '@tanstack/react-query';
import { FieldValues, UseFormSetValue, useWatch } from 'react-hook-form';
import DeleteIcon from '@mui/icons-material/Delete';
import TextFieldRG from 'src/components/Forms/TextFieldRG';
import { valorMonetario } from 'src/config/utils';
import { useEffect, useState } from 'react';
import TextFieldRGMaks from 'src/components/Forms/TextFieldRG/TextFieldMask';
import { getEmpenhosAPI } from 'src/services/Planejamento/FundoNacional/despesas';
import { getInstituicoes, getUnidadesInstituicao } from 'src/services/Admin/get';
import { getMetaInstituicao } from 'src/services/Planejamento/FundoNacional/metas';

export default function BuscarEmpenho({
  item,
  index,
  ExcluirEmpenho,
  setValue,
  metaInstituicaoId,
  watch,
}: {
  index: number;
  item: number;
  ExcluirEmpenho: (index: number) => void;
  setValue: UseFormSetValue<FieldValues>;
  metaInstituicaoId: string;
  watch: any;
}) {
  const [fetch, setFetch] = useState(false);
  const [valorEmpenho, setValorEmpenho] = useState<number | undefined>();
  const [instituicao, setInstituicao] = useState<string | undefined>();
  const numeroempenho = watch(`Empenhos[${item}].numeroEmpenho`);
  const instituicaoBeneficiadaId = watch(`Empenhos[${item}].instituicaoBeneficiadaId`);
  const unidadeBeneficiadaId = watch(`Empenhos[${item}].unidadeBeneficiadaId`);

  const { data: rows, refetch: refetchEmpenho } = useQuery({
    queryKey: ['getEmpenhos', numeroempenho],
    queryFn: () => getEmpenhosAPI(numeroempenho),
    enabled: false,
  });
  console.log(rows);
  const { data: rowsMetaInstituicao } = useQuery({
    queryKey: ['getEmpenhos', metaInstituicaoId],
    queryFn: getMetaInstituicao,
    enabled: !!metaInstituicaoId,
  });
  const { data: rowInstituicoes } = useQuery({
    queryKey: ['getInstituicoes'],
    queryFn: getInstituicoes,
  });
  const { data: rowsUnidades } = useQuery({
    queryKey: ['getUnidadesInstituicao', instituicao],
    queryFn: getUnidadesInstituicao,
    enabled: !!instituicao,
  });
  useEffect(() => {
    if (fetch && numeroempenho) {
      refetchEmpenho();
      setFetch(false);
    }
  }, [fetch, numeroempenho, refetchEmpenho]);
  useEffect(() => {
    if (rows) {
      setValorEmpenho(rows.valorEmpenhado);
      setValue(`Empenhos[${item}].valorEmpenho`, rows.valorEmpenhado);
      setValue(`Empenhos[${item}].valorAnulado`, rows.totalAnulado);
      setValue(`Empenhos[${item}].valorComplemento`, rows.totalComplemento);
      setValue(`Empenhos[${item}].valorPago`, rows.totalPago);
      setValue(`Empenhos[${item}].anoEmpenho`, rows.anoEmpenho);
      setValue(`Empenhos[${item}].dataEmpenho`, rows.dataEmpenho);
    } else {
      setValorEmpenho(undefined);
      setValue(`Empenhos[${item}].valorEmpenho`, undefined);
      setValue(`Empenhos[${item}].valorAnulado`, undefined);
      setValue(`Empenhos[${item}].valorPago`, undefined);
      setValue(`Empenhos[${item}].valorComplemento`, undefined);
      setValue(`Empenhos[${item}].anoEmpenho`, undefined);
      setValue(`Empenhos[${item}].dataEmpenho`, undefined);
    }
  }, [item, rows, setValue]);

  useEffect(() => {
    if (rowsMetaInstituicao && rowsMetaInstituicao.unidadeId) {
      setValue(`Empenhos[${item}].unidadeBeneficiadaId`, rowsMetaInstituicao.unidadeId);
      setInstituicao(rowsMetaInstituicao.instituicaoId);
    } else if (rowsMetaInstituicao) {
      setInstituicao(rowsMetaInstituicao.instituicaoId);
    }
  }, [item, rowsMetaInstituicao, setValue]);
  useEffect(() => {
    if (instituicaoBeneficiadaId) {
      setInstituicao(instituicaoBeneficiadaId);
    }
  }, [instituicaoBeneficiadaId, item]);
  useEffect(() => {
    if (rowsUnidades?.length === 0) {
      setValue(`Empenhos[${item}].unidadeBeneficiadaId`, undefined);
    }
  }, [item, rowsUnidades?.length, setValue, unidadeBeneficiadaId]);

  const handleSearch = () => {
    if (numeroempenho) {
      setFetch(true);
    }
  };
  console.log(valorEmpenho);
  return (
    <>
      <Grid item md={12} xs={12}>
        <Box sx={{ background: '#eef7f1', borderRadius: '5px', px: 1, color: 'green' }}>
          <Typography variant="subtitle2" sx={{ py: 1 }}>
            {index + 1} - Empenho Relacionado
          </Typography>
        </Box>
      </Grid>
      {rowsMetaInstituicao?.InstituicaoSigla === 'SISP' && (
        <Grid item xs={12} md={2}>
          <TextFieldRG
            select
            name={`Empenhos[${item}].instituicaoBeneficiadaId`}
            label="Instituição Beneficiada"
          >
            {rowInstituicoes?.results.map(
              (inst: any) =>
                inst.sigla !== 'SISP' && (
                  <MenuItem key={inst.id} value={inst.id}>
                    {inst.sigla}
                  </MenuItem>
                )
            )}
          </TextFieldRG>
        </Grid>
      )}
      {rowsMetaInstituicao?.unidadeId && (
        <Grid item xs={12} md={2}>
          <TextFieldRG
            select
            disabled
            name={`Empenhos[${item}].unidadeBeneficiadaId`}
            label="Unidade Beneficiada"
            value={rowsMetaInstituicao?.unidadeId}
          >
            {rowsUnidades?.map(
              (inst: any) =>
                inst.sigla !== 'SISP' && (
                  <MenuItem key={inst.id} value={inst.id}>
                    {inst.sigla}
                  </MenuItem>
                )
            )}
          </TextFieldRG>
        </Grid>
      )}
      {(!rowsMetaInstituicao?.unidadeId || instituicaoBeneficiadaId) &&
        rowsUnidades?.length > 0 && (
          <Grid item xs={12} md={2}>
            <TextFieldRG
              select
              name={`Empenhos[${item}].unidadeBeneficiadaId`}
              label="Unidade Beneficiada"
            >
              {rowsUnidades?.map(
                (inst: any) =>
                  inst.sigla !== 'SISP' && (
                    <MenuItem key={inst.id} value={inst.id}>
                      {inst.sigla}
                    </MenuItem>
                  )
              )}
            </TextFieldRG>
          </Grid>
        )}

      <Grid item xs={12} md={2}>
        <TextFieldRGMaks
          mask="____.______._____/____-__"
          label="Processo de Pagamento - SEI"
          name={`Empenhos[${item}].processoSeiPagamento`}
        />
      </Grid>

      <Grid item xs={12} md={2}>
        <TextFieldRGMaks
          mask="__________/____"
          label="Número do Empenho"
          name={`Empenhos[${item}].numeroEmpenho`}
        />
      </Grid>
      <Grid item xs={12} md={2} sx={{ textAlign: 'center' }}>
        <TextFieldRG
          disabled
          fullWidth
          label=""
          value={
            valorEmpenho !== undefined ? valorMonetario(valorEmpenho) : 'Empenho não Encontrado'
          }
          sx={{ my: 2 }}
          name={`Empenhos[${item}].valorEmpenho`}
        />
      </Grid>

      <Grid item xs={12} md={2}>
        <Box>
          <IconButton sx={{ m: 2 }} color="primary" size="large" onClick={handleSearch}>
            <SearchIcon />
          </IconButton>
          <IconButton
            sx={{ m: 2.5 }}
            color="error"
            size="large"
            onClick={() => ExcluirEmpenho(item)}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      </Grid>

      <Grid item xs={12} md={12} lg={10}>
        <TextFieldRG multiline rows={3} name={`Empenhos[${item}].descricao`} label="Descrição" />
      </Grid>
    </>
  );
}
