import { Box, Modal, Grid, IconButton, Alert } from '@mui/material';
import { useForm } from 'react-hook-form';
import { AuthContext } from 'src/Context/ContextUser';
import { useContext, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import DrawIcon from '@mui/icons-material/Draw';
import { useMutation, useQuery } from '@tanstack/react-query';
import FormsProvider from 'src/components/Forms/Provider';
import FormPlanoAplicacao from 'src/pages/Planejamento/FundoNacional/PlanoAplicacao/Formulario/Plano';
import { yupResolver } from '@hookform/resolvers/yup';
import { tratandoDataInput } from 'src/config/utils';
import {
  getPlanoAplicacaoId,
  patchAtualizarPlanoEixos,
} from 'src/services/Planejamento/FundoNacional/planoAplicacao';
import { ToastFun } from 'src/config/functions';
import { CustomContainerAtualizar } from 'src/components/CustomContainer/custom-container';
import { ErroResponse } from 'src/pages/types';
import ModalCarregamento from 'src/components/Modal/Carregamento';
import { DataPlano, EixoFinanciadoId, EixosFinanciados, schemaPlano } from '../Formulario/type';

export default function AtualizarPlano({
  open,
  handleClose,
  id,
  RefecthPlano,
}: {
  open: boolean;
  handleClose: () => void;
  id: string;
  RefecthPlano: () => void;
}) {
  const { state } = useContext(AuthContext);
  const include = ['eixoFinanciado'];

  const { data: rows, isPending } = useQuery<DataPlano>({
    queryKey: ['getPlanoAplicacao', id, include],
    queryFn: getPlanoAplicacaoId,
  });

  const methods = useForm({
    resolver: yupResolver(schemaPlano),
    defaultValues: rows,
  });
  // SALVAR ATUALIZAÇÃO - RAQUISIÇÃO
  const { mutate } = useMutation({
    mutationFn: patchAtualizarPlanoEixos,
    onSuccess: (data) => {
      ToastFun('Plano Atualizado com sucesso', 'success');
      RefecthPlano();
      handleClose();
    },
    onError: (error: ErroResponse) => {
      ToastFun(error.response.data?.message, 'error');
    },
  });

  // ATIBUINDO VALORES INPUT - TRATANDO VALORES DOS CAMPOS MONETÁRIOS
  useEffect(() => {
    if (rows) {
      rows.planoAplicacao.prazoExecucao = tratandoDataInput(rows.planoAplicacao.prazoExecucao);
      rows.eixosFinanciados.forEach((item: any) => {
        item.valorInicialCusteio /= 100;
        item.valorInicialInvestimento /= 100;
      });
      methods.reset(rows);
    }
  }, [methods, rows]);

  const { watch, setValue, unregister } = methods;

  // ENVIADO DADOS PARA A
  const onSubmit = async (data: any) => {
    data.planoAplicacao.usuarioUpdateId = state.usuario?.id;
    data.planoAplicacao.atualizadoEm = new Date();

    const eixoAtt: { id: string; eixoFinanciado: EixoFinanciadoId }[] = [];
    const eixoNew: EixosFinanciados[] = [];
    data.eixosFinanciados.map((item: any) => {
      if (item.id) {
        const dados = { id: item.id, eixoFinanciado: item };
        eixoAtt.push(dados);
      } else {
        eixoNew.push(item);
      }
      return item;
    });
    data.eixosFinanciados = eixoAtt;
    data.eixoFinanciadoNovos = eixoNew;

    if (state.usuario?.id) {
      data.planoAplicacao.usuarioCreateId = state.usuario.id;
    }
    console.log(data);
    const hasNegativeValues = data.eixosFinanciados.some(
      (item: any) =>
        item.eixoFinanciado.valorInicialCusteio < 0 ||
        item.eixoFinanciado.valorInicialInvestimento < 0
    );

    if (hasNegativeValues) {
      ToastFun('Os valores não pode ser negativo', 'error');
    } else {
      mutate(data);
    }
  };

  if (isPending) {
    return <ModalCarregamento isOpen={isPending} />;
  }
  return (
    <>
      {rows && (
        <Modal open={open} onClose={handleClose}>
          <Box
            sx={{
              background: 'white',
              borderRadius: '15px',
              p: 3,
              width: {
                xl: '58%',
                md: '75%',
                sm: '70%',
              },
              maxHeight: '95%',
              overflow: 'auto',
              scrollbarWidth: 'none',
              margin: 'auto',
              mt: 3,
            }}
          >
            <Grid container>
              <Grid md={12} xs={12} sx={{ textAlign: 'right' }}>
                <IconButton aria-label="delete" onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Grid>
              <CustomContainerAtualizar title="Plano de Aplicação do Fundo Nacional de Segurança Pública">
                <Grid md={12} xs={12}>
                  <Box p={2}>
                    <FormsProvider methods={methods} handleSubmit={onSubmit}>
                      <FormPlanoAplicacao setValue={setValue} unregister={unregister} rows={rows} />
                    </FormsProvider>
                  </Box>
                </Grid>
              </CustomContainerAtualizar>
            </Grid>
          </Box>
        </Modal>
      )}
    </>
  );
}
