import { Box, Grid, Paper, styled, Table, TableHead, TableRow, Typography } from '@mui/material';
import { TableCellBody, TableCellHead } from 'src/components/Table/CustomTableCell';
import {
  CalculandoPorcentagem,
  tratandoData,
  tratandoValorCentavos,
  valorMonetario,
} from 'src/config/utils';
import GovFed from 'src/assets/images/gov-fed.png';

export default function RelatorioPagamentos({
  rowsPlano,
  id,
  rowsPagamentos,
  rowsEixoFinanciadoMeta,
}: {
  rowsPlano: any;
  id: string;
  rowsPagamentos: any;
  rowsEixoFinanciadoMeta: any;
}) {
  return (
    <Grid item md={12}>
      <Paper sx={{ height: '65vh', p: 3, overflow: 'auto' }} id={id}>
        <Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCellHead size="small">
                  <img src={GovFed} alt="" width={100} />
                </TableCellHead>
                <TableCellHead colSpan={10}>
                  <Typography
                    className="title-cabecalho"
                    sx={{ fontSize: '20px ', color: '#082765', fontWeight: 800 }}
                  >
                    PLANO DE AÇÃO
                  </Typography>
                </TableCellHead>
                <TableCellHead>
                  <Typography
                    className="title-cabecalho"
                    sx={{ fontSize: '20px ', color: '#082765', fontWeight: 800 }}
                  >
                    {rowsPlano.planoAplicacao.exercicioOrcamentario}
                  </Typography>
                </TableCellHead>
                <TableCellHead>
                  <Typography
                    className="title-cabecalho"
                    sx={{ fontSize: '20px ', color: '#082765', fontWeight: 800 }}
                  >
                    AC
                  </Typography>
                </TableCellHead>
              </TableRow>
              <TableRow className="vertical">
                <TableCellHead
                  id="fundo"
                  size="small"
                  colSpan={14}
                  sx={{
                    background: '#FEE69C',
                  }}
                >
                  <Typography
                    className="title-cabecalho"
                    sx={{ fontSize: '20px ', color: '#082765', fontWeight: 800 }}
                  >
                    {rowsEixoFinanciadoMeta.nomeEixo}
                  </Typography>
                </TableCellHead>
              </TableRow>
              <TableRow>
                <TableCellHead
                  className="sub-title"
                  colSpan={14}
                  size="small"
                  sx={{ background: '#082765' }}
                >
                  <Typography sx={{ color: 'white', fontSize: '15px' }}>EMPENHOS</Typography>
                </TableCellHead>
              </TableRow>

              <TableRow>
                <TableCellHead
                  className="sub-title"
                  colSpan={3}
                  size="small"
                  sx={{ background: '#082765' }}
                >
                  <Typography sx={{ color: 'white', fontSize: '15px' }}>
                    NÚMERO DO PAGAMENTO
                  </Typography>
                </TableCellHead>
                <TableCellHead className="sub-title" size="small" sx={{ background: '#082765' }}>
                  <Typography sx={{ color: 'white', fontSize: '15px' }}>
                    DATA DO EMEPENHO
                  </Typography>
                </TableCellHead>
                <TableCellHead className="sub-title" size="small" sx={{ background: '#082765' }}>
                  <Typography sx={{ color: 'white', fontSize: '15px' }}>
                    VALOR DO PAGAMENTO
                  </Typography>
                </TableCellHead>

                <TableCellHead className="sub-title" size="small" sx={{ background: '#082765' }}>
                  <Typography sx={{ color: 'white', fontSize: '15px' }}>
                    VALOR CONSIGNAÇÕES
                  </Typography>
                </TableCellHead>
                <TableCellHead
                  colSpan={8}
                  className="sub-title"
                  size="small"
                  sx={{ background: '#082765' }}
                >
                  <Typography sx={{ color: 'white', fontSize: '15px' }}>BENEFICIÁRIO</Typography>
                </TableCellHead>
              </TableRow>
            </TableHead>

            {rowsPagamentos?.map((objeto: any) => (
              <TableRow key={objeto.id}>
                <TableCellBody align="center" colSpan={3}>
                  {objeto.numeroPagamento}
                </TableCellBody>
                <TableCellBody align="center">{tratandoData(objeto.dataEmissao)}</TableCellBody>
                <TableCellBody align="center">
                  R$ {valorMonetario(objeto.valorPagamento)}
                </TableCellBody>
                <TableCellBody align="center">
                  R$ {valorMonetario(objeto.totalConsignacoes)}
                </TableCellBody>
                <TableCellBody colSpan={8} align="center">
                  {objeto.razaoSocial}
                </TableCellBody>
              </TableRow>
            ))}
          </Table>
        </Box>
      </Paper>
    </Grid>
  );
}
