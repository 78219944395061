import { Box, Grid, Paper, styled, Table, TableHead, TableRow, Typography } from '@mui/material';
import { TableCellBody, TableCellHead } from 'src/components/Table/CustomTableCell';
import { CalculandoPorcentagem, tratandoValorCentavos } from 'src/config/utils';
import GovFed from 'src/assets/images/gov-fed.png';

export default function RelatorioGeral({
  rowsPlano,
  id,
  rowsEixoFinanciadoMeta,
}: {
  rowsPlano: any;
  id: string;
  rowsEixoFinanciadoMeta: any;
}) {
  return (
    <Grid item md={12}>
      <Paper sx={{ height: '65vh', p: 3, overflow: 'auto' }} id={id}>
        <Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCellHead size="small">
                  <img src={GovFed} alt="" width={100} />
                </TableCellHead>
                <TableCellHead colSpan={10}>
                  <Typography
                    className="title-cabecalho-geral"
                    sx={{ fontSize: '20px ', color: '#082765', fontWeight: 800 }}
                  >
                    PLANO DE AÇÃO
                  </Typography>
                </TableCellHead>
                <TableCellHead>
                  <Typography
                    className="title-cabecalho-geral"
                    sx={{ fontSize: '20px ', color: '#082765', fontWeight: 800 }}
                  >
                    {rowsPlano.planoAplicacao.exercicioOrcamentario}
                  </Typography>
                </TableCellHead>
                <TableCellHead>
                  <Typography
                    className="title-cabecalho-geral"
                    sx={{ fontSize: '20px ', color: '#082765', fontWeight: 800 }}
                  >
                    AC
                  </Typography>
                </TableCellHead>
              </TableRow>
              <TableRow>
                <TableCellHead id="fundo" size="small" colSpan={13} sx={{ background: '#FEE69C' }}>
                  <Typography
                    className="title-cabecalho-geral"
                    sx={{ fontSize: '20px ', color: '#082765', fontWeight: 800 }}
                  >
                    {rowsEixoFinanciadoMeta.nomeEixo}
                  </Typography>
                </TableCellHead>
              </TableRow>
            </TableHead>
            <TableRow>
              <TableCellHead
                className="sub-title"
                colSpan={13}
                size="small"
                sx={{ background: '#082765' }}
              >
                <Typography sx={{ color: 'white', fontSize: '15px' }}>
                  RESPONSÁVEL PELO PREENCHIMENTO DAS INFORMAÇÕES
                </Typography>
              </TableCellHead>
            </TableRow>
            <TableRow>
              <TableCellHead size="small" className="sub-title-geral">
                Nome:
              </TableCellHead>
              <TableCellBody size="small" colSpan={13} className="text-aling">
                Argemiro Pereira dos Santos
              </TableCellBody>
            </TableRow>
            <TableRow>
              <TableCellHead size="small" className="sub-title-geral">
                Cargo/Função:
              </TableCellHead>
              <TableCellBody size="small" colSpan={13} className="text-aling">
                Diretor de Planejamento e Gestão Estratégica
              </TableCellBody>
            </TableRow>
            <TableRow>
              <TableCellHead size="small" className="sub-title-geral">
                Lotação:
              </TableCellHead>
              <TableCellBody size="small" colSpan={13} className="text-aling">
                Diretoria de Planejamento e Gestão Estratégica - DIPLAGE
              </TableCellBody>
            </TableRow>
            <TableRow>
              <TableCellHead
                className="sub-title"
                colSpan={13}
                size="small"
                sx={{ background: '#082765' }}
              >
                <Typography sx={{ color: 'white', fontSize: '15px' }}>METAS GERAIS</Typography>
              </TableCellHead>
            </TableRow>
            {rowsEixoFinanciadoMeta.metasGerais.map((item: any, index: number) => (
              <TableRow key={item.id}>
                <TableCellHead className="sub-title-geral" size="small" sx={{ width: '120px' }}>
                  {index + 1} - Meta Geral:
                </TableCellHead>
                <TableCellBody size="small" colSpan={13} className="text-aling">
                  {item.nome}
                </TableCellBody>
              </TableRow>
            ))}
            <TableRow>
              <TableCellHead
                className="sub-title"
                colSpan={13}
                size="small"
                sx={{ background: '#082765' }}
              >
                <Typography sx={{ color: 'white', fontSize: '15px' }}>METAS FÍSICAS</Typography>
              </TableCellHead>
            </TableRow>
            <TableRow>
              <TableCellHead
                className="sub-title"
                colSpan={8}
                size="small"
                sx={{ background: '#082765' }}
              >
                <Typography sx={{ color: 'white', fontSize: '15px' }}>DESCRIÇÃO</Typography>
              </TableCellHead>
              <TableCellHead size="small" className="sub-title" sx={{ background: '#082765' }}>
                <Typography sx={{ color: 'white', fontSize: '15px' }}>INSTITUIÇÃO</Typography>
              </TableCellHead>
              <TableCellHead size="small" className="sub-title" sx={{ background: '#082765' }}>
                <Typography sx={{ color: 'white', fontSize: '15px' }}>NATUREZA</Typography>
              </TableCellHead>
              <TableCellHead size="small" className="sub-title" sx={{ background: '#082765' }}>
                <Typography sx={{ color: 'white', fontSize: '15px' }}>VALOR PLANEJADO</Typography>
              </TableCellHead>
              <TableCellHead size="small" className="sub-title" sx={{ background: '#082765' }}>
                <Typography sx={{ color: 'white', fontSize: '15px' }}>VALOR PAGO</Typography>
              </TableCellHead>
              <TableCellHead size="small" className="sub-title" sx={{ background: '#082765' }}>
                <Typography sx={{ color: 'white', fontSize: '15px' }}>% EXECUÇÃO</Typography>
              </TableCellHead>
            </TableRow>

            {rowsEixoFinanciadoMeta.metasGerais.map((item: any, index: number) => (
              <>
                {item.metasFisicas?.map((obj: any) => (
                  <>
                    {obj.metasInstituicoes.map((objeto: any) => (
                      <TableRow key={item.id}>
                        <TableCellBody colSpan={8}>{obj.nome}</TableCellBody>
                        <TableCellBody align="center">{objeto.sigla}</TableCellBody>
                        <TableCellBody align="center">{obj.natureza}</TableCellBody>
                        <TableCellBody align="center">
                          R$ {tratandoValorCentavos(objeto.valorInicial)}
                        </TableCellBody>
                        <TableCellBody align="center">
                          R$ {tratandoValorCentavos(objeto.valorExecutado)}
                        </TableCellBody>
                        <TableCellBody align="center">
                          {CalculandoPorcentagem(
                            objeto.valorExecutado,
                            objeto.valorExecutado + objeto.saldoReal
                          )}{' '}
                          %
                        </TableCellBody>
                      </TableRow>
                    ))}
                  </>
                ))}
              </>
            ))}
            <TableRow>
              <TableCellHead colSpan={5} size="small">
                VALOR INICIAL
              </TableCellHead>
              <TableCellHead colSpan={9} size="small">
                R$ {tratandoValorCentavos(rowsEixoFinanciadoMeta.valorInicialTotal)}
              </TableCellHead>
            </TableRow>
            <TableRow>
              <TableCellHead colSpan={5} size="small">
                VALOR EXECUTADO
              </TableCellHead>
              <TableCellHead colSpan={9} size="small">
                R$ {tratandoValorCentavos(rowsEixoFinanciadoMeta.valorExecutadoTotal)}
              </TableCellHead>
            </TableRow>
            <TableRow>
              <TableCellHead colSpan={5} size="small">
                EXECUÇÃO
              </TableCellHead>
              <TableCellHead colSpan={9} size="small">
                {CalculandoPorcentagem(
                  rowsEixoFinanciadoMeta.valorExecutadoTotal,
                  rowsEixoFinanciadoMeta.valorExecutadoTotal +
                    rowsEixoFinanciadoMeta.valorSaldoTotal
                )}
                %
              </TableCellHead>
            </TableRow>
          </Table>
        </Box>
      </Paper>
    </Grid>
  );
}
