import Endpoints from 'src/services/endpoints';
import { Api } from 'src/services/Api';
import { DadosRemanejamento } from 'src/pages/Planejamento/FundoNacional/Financeiro/Remanejamento/Formulario/type';

// GET
export async function getRemanejamento({ queryKey }: { queryKey: any }) {
  const [_key, id] = queryKey;
  const response = await Api.get(`${Endpoints.remanejamento}/${id}`);

  return response.data;
}
export async function postVerificarRemanejamento({ queryKey }: { queryKey: any }) {
  const [_key, valores] = queryKey;
  const response = await Api.post(`${Endpoints.remanejamento}/verificar-remanejamento`, valores);

  return response.data;
}
export async function getRemanejamentoIdMeta({ queryKey }: { queryKey: any }) {
  const [_key, id] = queryKey;
  const response = await Api.get(`${Endpoints.remanejamento}/meta/${id}`);

  return response.data;
}
export async function getRendimentoIdMeta({ queryKey }: { queryKey: any }) {
  const [_key, id] = queryKey;

  const response = await Api.get(`${Endpoints.rendimento}/meta/${id}`);

  return response.data;
}

export async function getRemanejamentos({ queryKey }: { queryKey: any }) {
  const [_key, skip, take, search] = queryKey;
  const filters = { search };

  const response = await Api.get(Endpoints.remanejamento, {
    params: {
      skip,
      take,
      filters,
    },
  });
  return response.data;
}
// POST

export async function postCadastrarRemanejamento(dadosMetas: DadosRemanejamento) {
  const result = await Api.post(Endpoints.remanejamento, dadosMetas);

  return result;
}

// PATCH
export async function AutorizarRemanejamento(dadosAutorizacao: any) {
  const response = await Api.patch(`${Endpoints.remanejamento}/autorizar/${dadosAutorizacao.id}`, {
    dadosAutorizacao,
  });
  return response.data;
}
// DELETE
export async function ExcluirRemanejamento(dados: any) {
  const response = await Api.delete(`${Endpoints.remanejamento}/${dados}`);
  return response.data;
}
