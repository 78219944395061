import { Alert, Button, Grid, IconButton, Modal } from '@mui/material';
import Box from '@mui/material/Box';
import { useContext, useState } from 'react';
import FormsProvider from 'src/components/Forms/Provider';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import DrawIcon from '@mui/icons-material/Draw';
import { AuthContext } from 'src/Context/ContextUser';
import { useMutation, useQuery } from '@tanstack/react-query';
import { ToastFun } from 'src/config/functions';
import { ErroResponse } from 'src/pages/types';
import ModalVerificar from 'src/components/Modal/Verificar';
import {
  postCadastrarRemanejamento,
  postVerificarRemanejamento,
} from 'src/services/Planejamento/FundoNacional/remanejamento';
import FormularioRemanejamento from '../Formulario';
import { schemaRemanejamento } from '../Formulario/type';

export default function CadastrarRemanejamento({
  open,
  handleClose,
  RefecthRemanejamentos,
}: {
  open: boolean;
  handleClose: () => void;
  RefecthRemanejamentos?: () => void;
}) {
  const methods = useForm({
    resolver: yupResolver(schemaRemanejamento),
  });

  const { state } = useContext(AuthContext);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { setValue, watch } = methods;

  const { data: remanejamentos } = useQuery({
    queryKey: ['getVerificarRemanejamento', watch()],
    queryFn: postVerificarRemanejamento,
    enabled: !!watch(),
  });

  const { mutate } = useMutation({
    mutationFn: postCadastrarRemanejamento,
    onSuccess: () => {
      ToastFun('Remanejamento cadastrado com sucesso!', 'success');
      handleClose();
      RefecthRemanejamentos?.();
    },
    onError: (error: ErroResponse) => {
      ToastFun(error.response?.data?.message ?? 'Erro ao cadastrar!', 'error');
    },
  });
  const onSubmit = async (data: any) => {
    data.usuarioCreateId = state.usuario?.id;
    if (remanejamentos?.length > 0) {
      setIsModalOpen(true);
    } else if (data.valorDisponivel >= data.valorRemanejado) {
      if (data.valorRemanejado < 0) {
        ToastFun('O valor Remanejado não pode ser negativo!', 'error');
      } else {
        mutate(data);
      }
    } else {
      ToastFun('O valor Remanejado é maior que o valor Disponível!', 'error');
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          background: 'white',
          borderRadius: '15px',
          p: 5,
          width: {
            xl: '58%',
            md: '75%',
            sm: '70%',
          },
          maxHeight: '95%',
          overflow: 'auto',
          margin: 'auto',
          mt: 3,
        }}
      >
        <Grid container>
          <Grid item md={12} xs={12} sx={{ textAlign: 'right' }}>
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item md={12} xs={12}>
            <Alert
              sx={{ background: '#019362', color: 'white', fontWeight: '700' }}
              icon={<DrawIcon sx={{ color: 'white' }} />}
            >
              Remanejamento - Cadastro
            </Alert>
            <Box sx={{ my: 1 }}>
              <FormsProvider methods={methods} handleSubmit={onSubmit}>
                <>
                  <FormularioRemanejamento setValue={setValue} />
                  <Grid item xs={12} md={12} sx={{ textAlign: 'right' }}>
                    <Button
                      variant="contained"
                      color="success"
                      type="submit"
                      startIcon={<SaveIcon />}
                    >
                      Cadastrar
                    </Button>
                  </Grid>
                </>
              </FormsProvider>
            </Box>
          </Grid>
        </Grid>
        <ModalVerificar
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          dados={methods.getValues()}
          mutate={mutate}
          title="Remanejamento"
        />
      </Box>
    </Modal>
  );
}
