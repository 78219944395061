import { useContext } from 'react';
import { Box, Stack } from '@mui/material';
import CustomBreadcrumbs from 'src/components/CustomBreadcrumbs';
import { CustomContainerCad } from 'src/components/CustomContainer/custom-container';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormsProvider from 'src/components/Forms/Provider';
import { ToastFun } from 'src/config/functions';
import { useMutation } from '@tanstack/react-query';
import { AuthContext } from 'src/Context/ContextUser';
import { useNavigate } from 'react-router-dom';
import { postCadastrarMetasFisicas } from 'src/services/Planejamento/FundoNacional/metas';
import { ErroResponse } from 'src/pages/types';
import ModalCarregamento from 'src/components/Modal/Carregamento';
import { DataMetasFisicas, defaultValuesMetasFisicas, schemaMetasFisicas } from './types';
import MetasFisicas from '../../Formulario/MetasFisicas';

export default function CadastrarMetasFisicas() {
  const navigate = useNavigate();

  const methods = useForm({
    resolver: yupResolver(schemaMetasFisicas),
    defaultValues: defaultValuesMetasFisicas,
  });
  const { state } = useContext(AuthContext);

  const {
    watch,
    setValue,
    unregister,
    formState: { errors },
  } = methods;

  const { mutate, isPending } = useMutation({
    mutationFn: postCadastrarMetasFisicas,
    onSuccess: (data) => {
      navigate('/planejamento/fundonacional/metas');
      ToastFun('Metas Cadastradas com sucesso', 'success');
    },
    onError: (error: ErroResponse) => {
      ToastFun(error.response.data?.message, 'error');
    },
  });

  if (isPending) {
    return <ModalCarregamento isOpen={isPending} />;
  }

  const onSubmit = async (data: any) => {
    data.metasFisicas = data.metasFisicas
      .filter((meta: any) => meta !== null)
      .map((item: { metasInstituicoes: any[] }) => ({
        ...item,
        metasInstituicoes: item.metasInstituicoes.filter(
          (instituicao: any) => instituicao !== null
        ),
      }));

    if (state.usuario?.id) {
      data.usuarioCreateId = state.usuario.id;
    }
    const hasNegativeValues = data.metasFisicas.some((metaFisica: any) =>
      metaFisica.metasInstituicoes.some((instituicao: any) => instituicao.valorInicial < 0)
    );

    if (hasNegativeValues) {
      ToastFun('Existem valores negativos!', 'error');
    } else {
      mutate(data);
    }
  };

  return (
    <Box sx={{ m: 'auto' }}>
      <Stack spacing={5}>
        <CustomBreadcrumbs
          heading="Fundo Nacional de Segurança Pública"
          links={[
            { name: 'Planejamento', href: '/planejamento' },
            { name: 'Fundo Nacional', href: '/planejamento/fundonacional' },
            { name: 'Metas', href: '/planejamento/fundonacional/metas' },
            { name: 'Cadastro', href: '/planejamento/fundonacional/metas/cadastro' },
          ]}
        />
        <CustomContainerCad title="Metas do Plano de Aplicação do Fundo Nacional de Segurança Pública">
          <FormsProvider methods={methods} handleSubmit={onSubmit}>
            <MetasFisicas setValue={setValue} unregister={unregister} watch={watch} />
          </FormsProvider>
        </CustomContainerCad>
      </Stack>
    </Box>
  );
}
