import { useState } from 'react';
import { Box, Button, Chip, Grid, IconButton, Stack, TextField, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { CustomContainerList } from 'src/components/CustomContainer/custom-container';
import CustomBreadcrumbs from 'src/components/CustomBreadcrumbs';
import { useMutation, useQuery } from '@tanstack/react-query';
import TableContainer from 'src/components/Table';
import { GridColDef } from '@mui/x-data-grid';
import { tratandoData, tratandoValorCentavos } from 'src/config/utils';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import ModalExcluir from 'src/components/Modal/Excluir';
import { ToastFun } from 'src/config/functions';
import {
  ExcluirRemanejamento,
  getRemanejamentos,
} from 'src/services/Planejamento/FundoNacional/remanejamento';
import { ErroResponse } from 'src/pages/types';
import CadastrarRemanejamento from './Cadastrar';
import RemanejamentoVisualizar from './Visualizar';

export default function Remanejamentos() {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  });

  const skip = paginationModel.page * paginationModel.pageSize;
  const take = paginationModel.pageSize;

  const [id, setId] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setmodalType] = useState<string>();
  const [checked, setChecked] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');

  const {
    data: rows,
    refetch: RefecthRemanejamentos,
    isPending,
  } = useQuery({
    queryKey: ['getRemanejamento', skip, take, search],
    queryFn: getRemanejamentos,
  });

  const { mutate } = useMutation({
    mutationFn: ExcluirRemanejamento,
    onSuccess: (data: any) => {
      ToastFun('Excluido com Sucesso', 'success');
      RefecthRemanejamentos();
      handleCloseModal();
    },
    onError: (error: ErroResponse) => {
      ToastFun(error.response.data?.message, 'error');
    },
  });

  const handleButtonClick = (idPlano: string, type: string) => {
    setId(idPlano);
    setIsModalOpen(true);
    setmodalType(type);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setId(null);
  };
  const columns: GridColDef[] = [
    {
      field: 'metaRetirada',
      headerName: 'META FÍSICA - RETIRADA',
      flex: 4,

      renderCell: (params) => (
        <span>
          {params.row.metaFisicaRetirada.nome} <br /> <br />
          <span style={{ fontWeight: 700 }}>Instituição</span> -{' '}
          <span style={{}}> {params.row.metaInstituicaoRetirada.sigla}</span>
        </span>
      ),
    },

    {
      field: 'metaRecebida',
      headerName: 'META FÍSICA - RECEBIDA',
      flex: 4,
      renderCell: (params) => (
        <span>
          {params.row.metaFisicaRecebida.nome} <br /> <br />
          <span style={{ fontWeight: 700 }}>Instituição</span> -{' '}
          <span style={{}}> {params.row.metaInstituicaoRecebida.sigla}</span>
        </span>
      ),
    },

    {
      field: 'valorRemanejado',
      headerName: 'VALOR REMANEJADO',
      flex: 1,
      headerAlign: 'center',
      align: 'center',

      renderCell: (params) => <>R$ {tratandoValorCentavos(params.row.valorRemanejado)}</>,
    },
    {
      field: 'natureza',
      headerName: 'NATUREZA',
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'status',
      headerName: 'SITUAÇÃO',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <Chip
          label={params.row.status}
          sx={{ color: 'white' }}
          color={params.row.status === 'Pendente' ? 'warning' : 'success'}
        />
      ),
    },

    {
      field: 'actions',
      headerName: '',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <Box>
          <IconButton
            color="primary"
            onClick={() => handleButtonClick(params.row.id, 'Visualizar')}
          >
            <VisibilityIcon />
          </IconButton>
          {params.row.status === 'Pendente' && (
            <IconButton
              sx={{ color: 'gray' }}
              onClick={() => handleButtonClick(params.row.id, 'Excluir')}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </Box>
      ),
    },
  ];

  const inputSearch = () => {
    if (checked) {
      setSearch('');
    }
    setChecked((prev: boolean) => !prev);
  };

  return (
    <Box sx={{ m: 'auto' }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3} sx={{ my: 2 }}>
          <Stack direction="row" spacing={2}>
            <TextField size="small" fullWidth onChange={(event) => setSearch(event.target.value)} />
            <IconButton color="warning" sx={{ background: '#FADCB2', mx: 1 }} onClick={inputSearch}>
              <SearchIcon />
            </IconButton>
          </Stack>
        </Grid>
        <Grid item xs={12} md={9} sx={{ textAlign: 'right', my: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleButtonClick('', 'Cadastro')}
            startIcon={<AddIcon />}
          >
            Remanejamento
          </Button>
        </Grid>
        <Grid xs={12} mt={2}>
          {rows && (
            <CustomContainerList title="Remanejamentos">
              <TableContainer
                columns={columns}
                rows={rows?.results}
                rowCount={rows?.total}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
              />
            </CustomContainerList>
          )}
        </Grid>
      </Grid>
      {id && isModalOpen && modalType === 'Excluir' && (
        <ModalExcluir
          isOpen={isModalOpen}
          nomeAcao="Remanejamento"
          id={id}
          mutate={mutate}
          handleClose={handleCloseModal}
        />
      )}
      {id && isModalOpen && modalType === 'Visualizar' && (
        <RemanejamentoVisualizar open={isModalOpen} id={id} handleClose={handleCloseModal} />
      )}
      {isModalOpen && modalType === 'Cadastro' && (
        <CadastrarRemanejamento
          open={isModalOpen}
          handleClose={handleCloseModal}
          RefecthRemanejamentos={RefecthRemanejamentos}
        />
      )}
    </Box>
  );
}
