import { Alert, Box, Chip, Grid, IconButton, Modal, styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DrawIcon from '@mui/icons-material/Draw';
import { useQuery } from '@tanstack/react-query';
import { tratandoData, tratandoValorCentavos } from 'src/config/utils';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ModalCarregamento from 'src/components/Modal/Carregamento';
import { getMetaFisica } from 'src/services/Planejamento/FundoNacional/metas';
import {
  getRemanejamentoIdMeta,
  getRendimentoIdMeta,
} from 'src/services/Planejamento/FundoNacional/remanejamento';
import { useState } from 'react';
import { CustomContainerList } from 'src/components/CustomContainer/custom-container';

const TableCellHead = styled(TableCell)(({ theme }) => ({
  fontSize: '11px',
  fontWeight: 700,
  color: '#555555',
  textTransform: 'uppercase',
}));
const TableCellBody = styled(TableCell)(({ theme }) => ({
  fontSize: '14px',
  color: '#555555',
  textTransform: 'uppercase',
}));

export default function MetaFisicaVisualizaRendimentos({ id }: { id: string }) {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const skip = paginationModel.page * paginationModel.pageSize;
  const take = paginationModel.pageSize;

  const { data: rowsMeta, isPending } = useQuery({
    queryKey: ['getRemanejamentoIdMeta', id],
    queryFn: getRendimentoIdMeta,
  });

  if (isPending) {
    return <ModalCarregamento isOpen={isPending} />;
  }
  return (
    <Box
      sx={{
        background: 'white',
        borderRadius: '15px',
        p: 2,
        overflow: 'auto',
        scrollbarWidth: 'none',
        margin: 'auto',
        mt: 1,
      }}
    >
      <Grid container>
        <Grid md={12} xs={12}>
          <CustomContainerList title="Rendimentos" type="detalhes">
            <TableContainer>
              {/* DADOS DA META - ANO - EIXO -META GERAL */}
              <Table sx={{ minWidth: '100%', mt: 2 }} aria-label="spanning table">
                <TableHead sx={{ background: '#f5f5f5' }}>
                  <TableRow>
                    <TableCellHead>Instituição</TableCellHead>
                    <TableCellHead>Valor Alocado</TableCellHead>
                    <TableCellHead>STATUS</TableCellHead>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowsMeta?.results.map((item: any, index: number) => (
                    <TableRow key={item.id}>
                      <TableCellBody key={index}>
                        R$ {tratandoValorCentavos(item?.valor)}
                      </TableCellBody>
                      <TableCellBody key={index}>{item.InstituicaoNome}</TableCellBody>
                      <TableCellBody key={index}>
                        <Chip label={item.status} sx={{ color: 'white' }} color="success" />
                      </TableCellBody>
                    </TableRow>
                  ))}
                  {rowsMeta.total === 0 && (
                    <TableCellBody colSpan={5} sx={{ textAlign: 'center' }}>
                      Não há alocação de rendimento para essa meta.
                    </TableCellBody>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </CustomContainerList>
        </Grid>
      </Grid>
    </Box>
  );
}
