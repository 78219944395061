import { useState } from 'react';
import { Box, Grid, Stack, Button, IconButton, Chip, TextField, Grow } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { CustomContainerList } from 'src/components/CustomContainer/custom-container';
import CustomBreadcrumbs from 'src/components/CustomBreadcrumbs';
import TableContainer from 'src/components/Table';
import { GridColDef } from '@mui/x-data-grid';
import SearchIcon from '@mui/icons-material/Search';

export default function Ocorrencias() {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const skip = paginationModel.page * paginationModel.pageSize;
  const take = paginationModel.pageSize;

  const [id, setId] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setmodalType] = useState<string>();
  const [checked, setChecked] = useState<boolean>(false);
  const [search, setSearch] = useState<string>();

  const inputSearch = () => {
    if (checked) {
      setSearch('');
    }
    setChecked((prev: boolean) => !prev);
  };
  const rows = { results: [], total: 0 };
  //   const {
  //     data: rows,
  //     refetch: RefecthMetas,
  //     isPending,
  //   } = useQuery({
  //     queryKey: ['getMetas', skip, take, search],
  //     queryFn: getMetasGerais,
  //   });

  const handleButtonClick = (idAeronave: string, type: string) => {
    setId(idAeronave);
    setIsModalOpen(true);
    setmodalType(type);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setId(null);
  };

  const columns: GridColDef[] = [
    {
      field: 'aeronave',
      headerName: 'AERONAVE',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'comandante',
      headerName: 'COMANDANTE',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'copiloto',
      headerName: 'COPILOTOS',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'saidaBase',
      headerName: 'BASE',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'duracaoOcorrencia',
      headerName: 'DURAÇÃO DA OCORRÊNCIA',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'tipoOcorrencia',
      headerName: 'TIPO DA OCORRÊNCIA',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
    },
  ];

  return (
    <Box sx={{ m: 'auto' }}>
      <Stack spacing={2}>
        <CustomBreadcrumbs
          heading="Centro Integrado de Operações Aéreas
"
          links={[
            { name: 'Menu', href: '/operacional/ciopaer' },
            { name: 'Ocorrências', href: '/operacional/ciopaer/ocorrencias' },
          ]}
        />
        <Grid container spacing={2}>
          <Grid item xs={12} md={3} sx={{ my: 2 }}>
            <Stack direction="row" spacing={2}>
              <TextField
                size="small"
                fullWidth
                onChange={(event) => setSearch(event.target.value)}
              />
              <IconButton
                color="warning"
                sx={{ background: '#FADCB2', mx: 1 }}
                onClick={inputSearch}
              >
                <SearchIcon />
              </IconButton>
            </Stack>
          </Grid>

          <Grid item xs={12} md={9} sx={{ textAlign: 'right', my: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleButtonClick('', 'Cadastro')}
              startIcon={<AddIcon />}
            >
              Ocorrências
            </Button>
          </Grid>
          <Grid xs={12} mt={2}>
            <CustomContainerList title="Abastecimento" backgroundAlert="#FF8E38">
              {rows && (
                <TableContainer
                  columns={columns}
                  rows={rows.results}
                  rowCount={rows.total}
                  paginationModel={paginationModel}
                  onPaginationModelChange={setPaginationModel}
                />
              )}
            </CustomContainerList>
          </Grid>
        </Grid>
      </Stack>
    </Box>
  );
}
