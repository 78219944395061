import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { useMutation, useQuery } from '@tanstack/react-query';
import SubdirectoryArrowLeftIcon from '@mui/icons-material/SubdirectoryArrowLeft';
import { useNavigate, useParams } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ErroResponse } from 'src/pages/types';
import { ToastFun } from 'src/config/functions';
import { CustomContainerList } from 'src/components/CustomContainer/custom-container';
import CustomBreadcrumbs from 'src/components/CustomBreadcrumbs';
import { tratandoValorCentavos } from 'src/config/utils';
import ModalCarregamento from 'src/components/Modal/Carregamento';
import { AutorizarMeta, getMetaGeral } from 'src/services/Planejamento/FundoNacional/metas';
import { AuthContext } from 'src/Context/ContextUser';
import { useContext, useState } from 'react';
import ModalAutorizar from 'src/components/Modal/Autorizar';

const TypographyTitulo = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: 700,
  color: '#555555',
  textTransform: 'uppercase',
}));
const TypographyBody = styled(Typography)(({ theme }) => ({
  fontSize: '13px',
  color: '#555555',
  textTransform: 'uppercase',
}));

export default function MetaGeralVisualizarAutorizacao() {
  const { id } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { state } = useContext(AuthContext);
  const handleButtonClick = () => {
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const dadosAutorizacao = {
    id,
    usuarioAuthorizationId: state.usuario?.id,
  };
  const nav = useNavigate();

  const include = ['eixoFinanciado', 'user', 'planoAplicacao', 'metasFisicas'];
  const {
    data: rowsMetaGeral,
    refetch,
    isPending,
  } = useQuery({
    queryKey: ['getMetaGeral', id, include],
    queryFn: getMetaGeral,
  });
  const { mutate } = useMutation({
    mutationFn: AutorizarMeta,
    onSuccess: (data) => {
      refetch();
      ToastFun('Meta Autorizada com sucesso', 'success');
      nav('/planejamento/fundonacional/autorizacao');
    },
    onError: (error: ErroResponse) => {
      ToastFun(error.response.data?.message, 'error');
    },
  });

  if (isPending || !rowsMetaGeral) {
    return <ModalCarregamento isOpen={isPending} />;
  }
  return (
    <Box
      sx={{
        m: 'auto',
      }}
    >
      <CustomBreadcrumbs
        heading="Enfrentamento à Criminalidade Violenta"
        links={[
          { name: 'Planejamento', href: '/planejamento' },
          { name: 'Fundo Nacional', href: '/planejamento/fundonacional' },
          { name: 'Autorização', href: '/planejamento/fundonacional/autorizacao' },
        ]}
      />

      <Stack
        spacing={3}
        sx={{
          mt: '2rem',
        }}
      >
        <CustomContainerList title="Meta Geral - Autorização">
          <>
            <Box
              sx={{
                display: {
                  md: 'block',
                  sm: 'none',
                  xs: 'none',
                },
              }}
            >
              <Grid container p={2} spacing={1} justifyContent="right">
                {rowsMetaGeral.status === 'Pendente' &&
                  rowsMetaGeral.planoAplicacao.status === 'Pendente' && (
                    <Grid md={12}>
                      {rowsMetaGeral.status === 'Pendente' && (
                        <Alert
                          severity="warning"
                          color="warning"
                          sx={{ textTransform: 'uppercase' }}
                        >
                          Essa meta não pode ser autorizada pois está vinculada a um Plano não
                          Autorizado
                        </Alert>
                      )}
                    </Grid>
                  )}
                <Grid item md={8} xs={12} my={2}>
                  <Stack spacing={2}>
                    <TypographyTitulo>Meta Geral</TypographyTitulo>
                    <TypographyBody>{rowsMetaGeral?.nome}</TypographyBody>
                  </Stack>
                </Grid>

                <Grid item md={4} xs={12} my={2} sx={{ textAlign: 'center' }}>
                  <Stack spacing={2}>
                    <TypographyTitulo> Situação</TypographyTitulo>
                    <TypographyBody>
                      <Chip
                        label={rowsMetaGeral.status}
                        color={rowsMetaGeral.status === 'Pendente' ? 'warning' : 'success'}
                      />
                    </TypographyBody>
                  </Stack>
                </Grid>
                <Grid item md={8}>
                  <TypographyTitulo>Meta Física</TypographyTitulo>
                </Grid>
                <Grid item md={2}>
                  <TypographyTitulo>Natureza</TypographyTitulo>
                </Grid>
                <Grid item md={2}>
                  <TypographyTitulo>Despesa</TypographyTitulo>
                </Grid>

                <Grid item md={12}>
                  {rowsMetaGeral.metasFisicas.length === 0 && (
                    <Box sx={{ textTransform: 'uppercase' }}>Nenhuma Meta Física Cadastrada</Box>
                  )}
                  {rowsMetaGeral?.metasFisicas?.map((metaFisica: any, indexMetaFisica: number) => (
                    <Accordion key={metaFisica} sx={{ boxShadow: 0, p: 0 }}>
                      <AccordionSummary sx={{ p: 0 }} expandIcon={<ExpandMoreIcon />}>
                        <Grid container>
                          <Grid item md={8.2}>
                            <Box>
                              <TypographyBody>
                                <span
                                  style={{
                                    fontWeight: '700',
                                    margin: 5,
                                  }}
                                >
                                  {indexMetaFisica + 1}.
                                </span>
                                {metaFisica.nome}
                              </TypographyBody>
                            </Box>
                          </Grid>
                          <Grid item md={2}>
                            <TypographyBody>{metaFisica.natureza}</TypographyBody>
                          </Grid>
                          <Grid item md={1.8}>
                            <TypographyBody>{metaFisica.nomeDespesa}</TypographyBody>
                          </Grid>
                        </Grid>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container px={2} spacing={2}>
                          <Grid item md={8.1}>
                            <TypographyTitulo>Instituição Beneficiada</TypographyTitulo>
                          </Grid>
                          <Grid item md={2}>
                            <TypographyTitulo>Valor Inicial</TypographyTitulo>
                          </Grid>
                          <Grid item md={1.9}>
                            <TypographyTitulo>Unidade</TypographyTitulo>
                          </Grid>

                          {metaFisica.metasInstituicoes.map(
                            (metaFisicaInstituicao: any, indexMetaFisicaInstituicao: number) => (
                              <>
                                <Grid item md={8.1}>
                                  <TypographyBody>{metaFisicaInstituicao.nome}</TypographyBody>
                                </Grid>
                                <Grid item md={2}>
                                  <TypographyBody>
                                    R$ {tratandoValorCentavos(metaFisicaInstituicao.valorInicial)}
                                  </TypographyBody>
                                </Grid>
                                <Grid item md={1.9}>
                                  <TypographyBody>
                                    {metaFisicaInstituicao.unidade || 'Não se Aplica'}
                                  </TypographyBody>
                                </Grid>
                              </>
                            )
                          )}
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </Grid>
                <Grid item lg={1.5} md={2} my={2}>
                  <Button
                    fullWidth
                    href="/planejamento/fundonacional/autorizacao"
                    variant="contained"
                    color="info"
                    startIcon={<SubdirectoryArrowLeftIcon />}
                  >
                    Voltar
                  </Button>
                </Grid>

                {rowsMetaGeral.status === 'Pendente' &&
                  rowsMetaGeral.planoAplicacao.status === 'Autorizado' &&
                  id && (
                    <Grid item lg={1.5} md={2} my={2}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="success"
                        startIcon={<TaskAltIcon />}
                        onClick={() => handleButtonClick()}
                      >
                        Autorizar
                      </Button>
                    </Grid>
                  )}
              </Grid>
            </Box>
            <Box
              sx={{
                display: {
                  md: 'none',
                  sm: 'block',
                  xs: 'block',
                },
              }}
            >
              <Alert severity="warning">
                É NECESSÁRIO ESTÁ NO COMPUTADOR PARA VISUALIZAR E AUTORIZAR O PLANO!
              </Alert>
              <Button
                variant="contained"
                href="/planejamento/fundonacional/autorizacao"
                color="info"
                sx={{ m: 2 }}
                startIcon={<SubdirectoryArrowLeftIcon />}
              >
                Voltar
              </Button>
            </Box>
          </>
        </CustomContainerList>
      </Stack>
      {id && (
        <ModalAutorizar
          isOpen={isModalOpen}
          nomeAcao="Meta Geral"
          id={id}
          mutate={mutate}
          handleClose={handleCloseModal}
        />
      )}
    </Box>
  );
}
